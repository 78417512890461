.engageDashboard {
    .engageLanding {
        .flex-display();

        .pi-dashboard_subtitle {
            .engageDashboard_engageLanding_engageSubTitle_info {
                font-weight: 400;
            }

            .engageDashboard_engageLanding_engageSubTitle_contact {
                color: @kingfisher;
            }
        }

        .engageDashboard_engageLanding_engageTitle_icon {
            height: 45px;
            color: @electric-violet;
            margin: 21px 0px 0px 45px;
        }
    }

    .engageLeap {
        margin-top: 75px;
        margin-right: 34px;
        width: 510px;

        .engageLeap_container {
            background-color: @concrete;
            .flex-display();
            .flex-flow(column);
            .align-items(left);
            padding: 30px;

            .engageLeap_container_engageLeap_header {
                font-size: 18px;
                font-weight: 500;
            }

            .engageLeap_container_engageLeap_info {
                font-size: 14px;
                padding-top: 12px;
                text-align: left;
                padding: 16px 0px;
            }
        }
    }
}
