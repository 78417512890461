.accessToggle {
    margin-top: 10px;
    margin-left: 10px;
    @ballRadius: 12px;

    .piToggle_toggleArea {
        height: @ballRadius + 4;
        width: 28px;
        border-radius: @ballRadius;
        line-height: @ballRadius;
        background-color: @color-neutral-400;

        .piToggle_toggleArea_ball {
            margin: 2px;
            background-color: white;
            height: @ballRadius;
            width: @ballRadius;
            border-radius: @ballRadius;
        }
    }

    .piToggle_label {
        line-height: @ballRadius + 3;
        font-weight: 500;
    }

    .piToggle-on {
        .piToggle_toggleArea {
            background-color: @color-primary;

            .piToggle_toggleArea_ball {
                margin: 2px -2px;
                left: @ballRadius + 3;
            }
        }
    }

    .piToggle-disabled {
        cursor: unset;
        .piToggle_toggleArea {
            background-color: @color-neutral-400;
        }
    }
}

.user-searchBar {
    position: relative;

    input {
        padding-left: 34px;
        font-size: 13px;
        max-width: 413px;
        height: 40px;
    }

    &:after {
        position: absolute;
        top: 14px;
        left: 14px;
        .color-primary;
        .font-awesome-icon(@fa-var-search);
        bottom: 0;
    }
}

.disableUserCheckBox {
    .checkbox {
        margin-top: 20px;
        margin-bottom: 0px;

        span {
            .extraSmallText;
        }
    }
}
