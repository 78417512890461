hoverable-learning-tip {
    .flex-display();
    .flex-flow(column);
    padding: 15px;
    background: @foam;
    border: 1px solid @sail;

    .hoverableLearningTip_title {
        font-weight: 500;
        margin-bottom: 15px;
    }

    .hoverableLearningTip_content {
        .flex-display();
    }

    .hoverableLearningTip_content_item {
        margin-right: 5px;
        padding: 5px;

        &:not(.hoverableLearningTip_content_item--noHover) {
            cursor: pointer;
            // Give some sort of affordance to user that this is hoverable
            border: 2px dashed darken(@sail, 10%);

            &:hover {
                background: @sail;
            }
        }
    }

    .hoverableLearningTip_content_item_title {
        font-weight: 500;
    }
}
