.dashboardCard {
    position: relative;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    color: #24133e;
    width: 100%;
    min-height: 340px;
    background: white;
    display: grid;
    grid-template-columns: 25px 1fr 15px auto 15px auto 25px;
    grid-template-rows: 52px 57px 57px 57px 57px 60px;
}

.dashboardCard-header {
    grid-row: 1;
    grid-column: ~'1/8';
    border-bottom: 1px solid #d1d1d1;
}

.dashboardCard-header-name {
    grid-row: 1;
    margin: auto 0;
}
