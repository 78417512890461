.pi-breadcrumb {
    padding-left: 0px; // this is to overcome the normal paddin gofr a UL
    font-size: 18px; // TODO - this perhaps should be part of a 'text style'
    font-weight: 500;
    margin-bottom: 10px; // can this margin be pulled in from some more genral source?

    .pi-breadcrumb__entry {
        // This may go away based on some normalization work being done by Eddie
        // Leaving it in for now
        display: inline-block; // to overcome display: block of li
    }
    .pi-breadcrumb__spacer {
        padding: 0 4px;
    }
}
