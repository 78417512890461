// This must match a field in OnboardingChecklistComponent
@onboardingCheckIconBoxSize: 100px;
@circleDifference: 15px;

.onboardingChecklist {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: @onboardingCheckIconBoxSize auto;
    grid-template-rows: @onboardingCheckIconBoxSize auto;
    width: 1000px;
}

.onboardingChecklist-startSpace {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
}

.onboardingChecklist-iconHolder {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
    border-radius: (@onboardingCheckIconBoxSize - (2 * @circleDifference))/ 2;
    margin: @circleDifference;
}

.onboardingChecklist-iconHolder--selected {
    margin: 0px;
    border-radius: @onboardingCheckIconBoxSize / 2;
}

@onboardingChecklistLineWidth: 8px;

.onboardingChecklist-lineHolder {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboardingChecklist-lineHolder-line {
    margin: 0 2px;
    width: 100%;
    height: @onboardingChecklistLineWidth;
    background-color: @color-neutral-200;
}

.onboardingChecklist-textSpace {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    grid-row: 2 / span 2;
    text-align: center;
    margin-top: 16px;
    color: @color-primary-900;
    font-size: 16px;
    font-weight: 500;
}

.moveLeft-12 {
    position: relative;
    left: -12px;
}

.moveLeft-7 {
    position: relative;
    left: -7px;
}

.disableButton {
    cursor: not-allowed;
}

.normalCursor {
    cursor: default;
}

.boldPurpleFont {
    color: @jacarta;
    font-weight: bold;
}

.transparentize {
    opacity: 0.4;
    filter: alpha(opacity=40); // for IE8 and eariler
}

.hoverRemoveTransparency {
    &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
    }
}

.removeTransparency {
    opacity: 1;
    filter: alpha(opacity=100);
}

.blueBackground_spaceContent {
    background-color: #edf8fe;
    height: 300px;
    width: 100%;
    padding-top: 60px; // distance from buttons to top of blue banner
    margin-top: 100px; // spacing above blue background
    align-content: center;
}

.circleShadow {
    box-shadow: 0 3px 5px grey;
}

.greyBackground-verticalPadding {
    background-color: @color-neutral-50;
    padding: 25px 0 0 70px;
}

.background--blue {
    background-color: @color-person-700;
}
.background--red {
    background-color: @color-danger;
}
.background--purple {
    background-color: @color-primary;
}
.background--orange {
    background-color: @color-job;
}
.background--green {
    background-color: @color-success;
}
.background--grey {
    background-color: @color-neutral;
}
.background--gold {
    background-color: @color-warning;
}

.whiteCircleBackgroundColorBorderAndText(@color) {
    background-color: white;
    width: 30px;
    height: 30px;
    color: @color;
    border-radius: 50%;
    border-color: darken(@color, 10%);
    border-style: solid;
    border-width: 3px;
}

.popoutBlue {
    .whiteCircleBackgroundColorBorderAndText(@color-person-700);
}
.popoutRed {
    .whiteCircleBackgroundColorBorderAndText(@color-danger);
}
.popoutPurple {
    .whiteCircleBackgroundColorBorderAndText(@color-primary);
}
.popoutOrange {
    .whiteCircleBackgroundColorBorderAndText(@color-job);
}
.popoutGreen {
    .whiteCircleBackgroundColorBorderAndText(@color-success);
}

.moveUpSmall {
    color: white;
    position: relative;
    top: -12px;
}

.negativeMargin25 {
    margin: -25px;
}

.marginTop24 {
    margin-top: 24px;
}

.makeCircle {
    border-radius: 50%;
}

.displayNone {
    display: none;
}

.checkmark_moveRightUp {
    position: relative;
    right: -32px;
    top: -8px;
}
