@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/guide.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

@mobileFlexItemMargin: 5px;
@flexItemMargin: 3%;

.jobDashboard {
    .jobDashboard_content {
        max-width: 100%;
    }

    .jobDashboard_routerWrapper > * {
        width: 100%;
    }
}

.desktop({
    .jobDashboard {
        .jobDashboard_content {
            margin: 0 -@flexItemMargin; width: auto;
        }

        .jobDashboard_routerWrapper > * {
            width: auto;
        }
    }
});

.jobDashboard {
    background: white;

    copy-button {
        input {
            width: 400px;
            padding: 0px;
        }
        div {
            .layoutHorizontal;
        }
    }

    pi-table {
        overflow: scroll;
    }

    .jobDashboard_routerWrapper,
    .jobDashboard > loading-dots {
        background: @concrete;
        min-height: 100vh;
        margin: 0 -@dashboardGutter;
        padding: @dashboardGutter;
        border-top: 1px solid @silver;
    }

    .jobDashboard_assessmentResults {
        margin: 0 (@dashboardGutter / 2) 0;
        width: calc(~'100% - @{dashboardGutter} * 2');
    }

    .jobDashboard_content {
        .flex-display();
        .flex-wrap(wrap);
        .flex-auto();
    }

    .jobDashboard_routerWrapper {
        .flex-flow(column);

        router-outlet {
            display: none;
        }
        > * {
            .flex-auto();
        }
    }

    .jobDashboard_jobInfo {
        margin-bottom: 25px;
        font-weight: 500;

        span {
            margin-right: 20px;
        }
    }

    .dashboard_referenceInfo {
        padding: 26px 26px 0;
        font-weight: bold;
        font-size: 17px;

        .dashboard_referenceInfo--icon {
            height: 24px;
            margin-right: 4px;
        }
    }

    .jobDashboard_navigationControls {
        .flex-display();
        .justify-content(space-between);

        .jobDashboard_navigationControls_adminDropdown {
            max-width: 250px;
            .singleLineHeightIE10(35px);
            width: auto;
            .flex(0 1 auto);
        }
    }

    .pi-card {
        margin-bottom: 0;
        height: auto;
    }
    .assessment-details-card {
        margin-top: @flexItemMargin * 2;

        .pi-card_content {
            padding-top: 12px;
        }
    }
    .behavioralCard,
    .cognitiveCard {
        .pi-card .pi-card_header .pi-card_header_link:hover {
            background: @concrete;
            border-color: @job;
        }
        .pi-card_dropdownContent {
            min-width: 280px;
        }
        .pi-card_dropdownContent_item {
            .flex-display();
            cursor: pointer;
            > * {
                .flex-auto();
            }
            behavioral-thumbnail,
            cognitive-thumbnail {
                .flex(0 1 160px);
            }
        }
    }
}

.watermark-content {
    text-align: center;
    .fa-clock-o {
        color: @torch;
    }
    .watermark-image {
        height: 94px;
        background-size: 80px 60px;
        padding-bottom: 14px;
    }
}

.behavioralReport {
    .behavioralReport_step {
        font-size: 16px;
        font-weight: 400;
        span {
            color: @puerto-rico;
            font-weight: 500;
        }
    }
}

job-target-report-card {
    section {
        font-size: 14px;

        ul {
            list-style: none;
        }

        li {
            .capitalizeFirstLetter();
            font-weight: 300;

            .adjective {
                font-weight: initial;
            }
        }
    }
}

job-match-score-weight-text {
    margin-top: 10px;
}

.acceptJobTargetPage .jobDashboard_cognitiveWeight {
    width: 100%;
    .flex-auto();
    margin-bottom: @dashboardGutter;

    .pi-card_content {
        min-height: auto;
    }
}

job-cognitive-weight-slider {
    position: relative;
    margin: 0 10px;
}

@tickHeight: 10px;
@lineWidth: 3px;

.cogSlider_labels {
    .flex-display();
    .flex-auto();
    color: @silver;
    height: 18px;

    > * {
        .flex-auto();
        width: 0;
        position: absolute;
        width: 36px;
        margin-left: -13px;
        text-align: center;
        cursor: pointer;

        &.active {
            color: @job;
        }
    }
}

.cogSlider_ticks {
    .flex-display();
    .flex-auto();

    > * {
        .flex-auto();
        .flex-display();

        &:before {
            content: '';
            border-left: @lineWidth solid @silver;
            height: 2 * @tickHeight;
            position: absolute;
            width: 0;
            cursor: pointer;
        }
    }
}

.cogSlider_line {
    border-bottom: @lineWidth solid @silver;
    height: 10px;
    margin-bottom: 20px;
    margin-top: @lineWidth / 2;
}

.cogSlider_slider {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 20px;
    height: 20px;
    background: @job;
    border-radius: 100px;
    margin-left: -10px;

    cursor: pointer;
}