.loginApp {
    .auth0-lock.auth0-lock,
    .auth0-lock.auth0-lock * {
        box-sizing: border-box;
    }
    .auth0-lock {
        &.auth0-lock {
            font-family: 'Roboto', sans-serif;
            .auth0-lock-cred-pane-internal-wrapper {
                height: initial;
                min-height: initial;
                max-height: initial;
            }
            .auth0-lock-header {
                padding: 0 0 30px 0;
                overflow: visible;
            }
            .auth0-lock-header-bg {
                background: none;
            }

            .auth0-lock-header-logo {
                display: none;
            }
            .auth0-lock-name {
                font-weight: 500;
                text-align: left;
                font-size: 45px;
                .color-neutral-900;
                text-overflow: none;
                white-space: normal;
                overflow: visible;
                padding: 0;
                line-height: 1.2em;
            }

            .auth0-lock-input-block {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 20px;
                }
            }

            .auth0-lock-form {
                padding: 0;
                .auth0-lock-input-wrap {
                    background-color: white;
                    border-color: @color-neutral-400;
                    &.auth0-lock-focused {
                        border-color: @color-primary;
                    }
                    .auth0-lock-input-wrap-with-icon {
                        padding-left: 35px;
                    }
                    .auth0-lock-input {
                        transition: all 0.2s ease-in-out;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                        position: relative;
                        display: inline-block;
                        color: @color-neutral-700;
                        background-color: white;
                        height: 45px;
                        margin: 0;
                        padding-left: 5px;
                        padding-right: 11px;
                        border-radius: 2px;
                        box-shadow: none;
                        &::placeholder {
                            color: @color-neutral;
                        }
                    }
                }
                .auth0-lock-alternative {
                    text-align: left;
                    margin: 20px 0 30px 0;
                    .auth0-lock-alternative-link {
                        .color-primary;
                        .smallText;
                        text-decoration: underline;
                        &:hover,
                        &:focus {
                            color: @color-primary-link-hover;
                        }
                    }
                }
            }

            .auth0-lock-submit {
                .button();
                font-size: 14px;
                padding: 15px 17px;
                text-transform: none;
                color: #fff;
                background-color: @color-primary !important; //Auth0 has inline color set
                svg {
                    display: none;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    background-color: @color-primary-400 !important; //Auth0 has inline color set;
                }
                &:active {
                    background-color: @color-primary-900 !important; //Auth0 has inline color set;
                }
                .auth0-loading-container {
                    .auth0-loading {
                        top: -4px;
                    }
                }
                .auth0-label-submit {
                    height: auto;
                    line-height: inherit;
                    transform: none !important;
                }
            }

            .auth0-lock-form {
                p {
                    .smallText;
                    text-align: left;
                    line-height: 1.55em;
                    padding-bottom: 10px;
                    color: @color-neutral-900;
                }
            }
            .auth0-lock-back-button {
                left: -50px;
            }

            .auth0-global-message {
                box-shadow: 0 0 0 0 transparent;
                display: flex;
                position: relative;
                align-items: center;
                padding: 15px;
                border-width: 1px;
                border-radius: 4px;
                color: @color-neutral-900;
                background-color: @color-neutral-100;
                border-color: rgba(@color-danger, 0.3);
                border-width: 2px;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
            }
            .auth0-lock-error-invalid-hint {
                .help-block();
                color: @color-danger;
            }
            .auth0-global-message.auth0-global-message-success {
                background: rgba(@color-success, 0.2);
                border-color: rgba(@color-success, 0.3);
                margin-bottom: 20px;
            }
            .auth0-global-message {
                text-transform: none;
            }
        }
    }

    .auth0-lock-header-bg-blur {
        background-image: none !important; // Override inline style
    }

    .auth0-lock-header-bg-solid {
        background-color: white !important; // Override inline style for Edge
    }

    .auth0-lock-forgot-password-italics {
        margin-top: 20px;
        color: @color-neutral-600;
    }
}
