@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
engage-error {
    .flex-display();
    .flex-direction(column);
    .errorDisplay {
        padding: 18px;
        font-size: 18px;
        font-weight: bold;
        .engageDisplay_headMessage {
            .errorIcon {
                color: red;
                margin-right: 12px;
            }
        }
        .errorDisplay_list {
            margin-left: 50px;
            list-style: disc;
            li {
                padding: 12px 6px;
            }
        }
        .errorDisplay_contact {
            margin-top: 30px;
            margin-left: 48px;
            font-weight: normal;
            &.errorDisplay_contact > a {
                color: @kingfisher;
            }
        }
    }
}
