@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/guide.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

@diameter: 77px;
@progressBorderWidth: 9px;

target-indicator,
circular-completion-indicator {
    .flex-display();
    .justify-content(center);

    .indicator {
        position: relative;
        height: @diameter;
        width: @diameter;
        border-radius: @diameter;
        background: @concrete;

        .circularIndicator-percentageIndicator {
            width: 100%;
            height: 100%;
            .TransformProperties(rotateY(180deg) ;);

            .mask,
            .fill {
                width: @diameter;
                height: @diameter;
                position: absolute;
                border-radius: @diameter;

                backface-visibility: hidden;
                .TransitionProperties(transform 0.5s linear;);
            }

            .mask {
                clip: rect(0px, @diameter, @diameter, @diameter / 2);
                .fill {
                    clip: rect(0px, @diameter / 2, @diameter, 0px);
                    background: @sky; //linear-gradient(#1a5fCC, @person);
                }
            }
            /*.mask.half .fill.fix {
                background: linear-gradient(#2f2fBB, #1a5fCC);
            }*/

            .circle {
                background: @sky;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -@progressBorderWidth / 2;
                width: @progressBorderWidth;
                height: @progressBorderWidth;
                border-radius: @progressBorderWidth;
                .TransitionProperties(transform 0.5s linear;);
            }

            &.circularIndicator-percentageIndicator_complete {
                .mask .fill,
                .circle {
                    background: @person;
                }
            }
        }

        .indicator-valueDisplay {
            position: absolute;
            left: @progressBorderWidth;
            top: @progressBorderWidth;
            background: white;
            color: @person;
            width: @diameter - @progressBorderWidth*2;
            height: @diameter - @progressBorderWidth*2;
            border-radius: @diameter - @progressBorderWidth*2;
            font-size: 22px;
            .flex-display();
            .flex-direction(column);
            .justify-content(center);
            text-align: center;
        }

        &.indicator_target {
            .indicator-valueDisplay {
                color: @mineshaft;
                left: 6px;
                top: 6px;
                bottom: 6px;
                right: 6px;
                width: auto;
                height: auto;

                .indicator-valueDisplay-siteHolder {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    .flex-display();
                    .justify-content(center);

                    .indicator-valueDisplay-targetSites {
                        margin-top: -12px;
                        height: 20px;
                        width: 4px;
                        background: @concrete;
                    }
                }
            }

            &.indicator_target-urgent {
                background: @wisp;

                .indicator-urgentNotice {
                    position: absolute;
                    left: @progressBorderWidth;
                    top: @progressBorderWidth;
                    background: @failureRed;
                    color: @failureRed;
                    width: @diameter - @progressBorderWidth*2;
                    height: @diameter - @progressBorderWidth*2;
                    border-radius: @diameter - @progressBorderWidth*2;

                    .indicator-urgentNotice-inset {
                        position: absolute;
                        left: @progressBorderWidth*1.25;
                        right: @progressBorderWidth*1.25;
                        top: @progressBorderWidth*1.25;
                        bottom: @progressBorderWidth*1.25;
                        border-radius: @diameter;
                        background: white;
                        font-size: 30px;
                        line-height: 40px;
                        text-align: center;
                    }
                }
            }
        }

        &.disabled {
            .circularIndicator-percentageIndicator {
                display: none;
            }

            .indicator-valueDisplay {
                background: @alto;
                color: @nobel;
                .box-shadow(0px 0px 6px 3px @silver inset;);
            }
        }

        &.editing {
            .indicator-valueDisplay {
                color: @concrete;
            }
        }
    }
}
