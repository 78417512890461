@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/variables.less';

@activeCircleDiameter: 71px;
@inactiveCircleDiameter: 59px;
@spaceBetweenDotsAndLines: 8px;

job-flow-guidance {
    margin: 30px;
}

.jobFlowGuidance {
    margin: 25px 45px;
    display: grid;
    grid-template-rows: repeat(7, @activeCircleDiameter auto);
    grid-template-columns: @activeCircleDiameter auto;
}

.jobFlowGuidance-headerText {
    font-size: 20px;
    font-weight: bold;
    color: @color-person-200;
}

.jobFlowGuidance-descriptionText {
    font-size: 20px;
    display: none;
}

.jobFlowGuidance-blueCircle {
    border-radius: 50%;
    background-color: @color-person-200;
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-end: span 1;
    margin: (@activeCircleDiameter - @inactiveCircleDiameter) / 2;
}

.jobFlowGuidance-blueCircle--active {
    background-color: @color-person;
    margin: 0px;
}

.jobFlowGuidance-grayLineLocation {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-end: span 1;
}
.jobFlowGuidance-grayLine {
    width: 5px;
    min-height: @inactiveCircleDiameter;
    margin-left: 50%;
    background-color: @color-neutral;
}

.jobFlowGuidance-textSection {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-end: span 2;
    padding-left: 30px;
    margin-top: 9px;
}
.jobFlowGuidance-textSection--active {
    margin-top: 0px;
    .jobFlowGuidance-descriptionText {
        display: block;
    }
    .jobFlowGuidance-headerText {
        color: @color-primary-900;
    }
}
