@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/okapi.less';

@title-left-padding: 10px;
@height-ui-to-pdf-multiplier: 0.71;

.portable-person-component {
    // place classes here that are not size-specific

    .video-link-color {
        // used to assign color to the hyperlink in "watch a quick video on..."
        color: @color-primary-400;
    }
    a {
        color: @electric-violet;
        font-weight: bold;
    }
    .vertical-align {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }
    .cold-purple {
        color: @cold-purple;
    }
    .pacific {
        color: @pacific;
    }
    .significance-tag {
        color: @color-person-700;
    }
    .black {
        color: black;
    }
    .bold-text {
        font-weight: bold;
    }
    .baseline-align {
        align-items: baseline;
    }
    .display-two-children > :nth-child(n + 3) {
        display: none; // hides 3rd and beyond elements of a list
    }
    .display-three-children > :nth-child(n + 4) {
        display: none; // hides all elements of list beyond first three
    }
    .blue-background {
        background-color: @color-person-200;
    }

    .ui {
        // README: Because the style needs to be different depending on load case the classes
        // are duplicated. If a class is updated or added, make the changes to both UI and PDF
        // sections of this file.

        sharing-bar {
            width: 300px;
        }

        // place classes here that are have size-specific elements

        .body-prediction-container {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 0 18px;
        }

        .body-base-container {
            display: flex;
            background-color: @color-person-200;
            justify-content: space-around;
            padding: 10px 85px 30px 75px;
        }

        .reference-pattern-image {
            float: left;
            height: 70px;
            padding: 11px 0 8px 36px;
        }

        .card-subheader {
            font-weight: 500;
            font-size: 16px;
            position: relative;
            left: -8px;
        }

        .card-content {
            line-height: 26px;
            font-size: 16px;
            color: @color-neutral-900;
            padding-bottom: 5px;
        }

        .sub-header-container {
            display: flex;
            background-color: @color-person-200;
            justify-content: flex-start;
            height: 76px;
        }

        .body-heading-candidate-describe {
            padding-left: @title-left-padding;
        }

        .body-heading-explore-pattern {
            font-size: 18px;
            font-weight: 500;
            line-height: 16px;
            padding-left: @title-left-padding;
            padding-top: 6px;
        }

        .body-prediction-block {
            background-color: @color-neutral-50;
            padding: 5px;
            padding-left: 20px;
            color: @port-gore;
            width: 23%;
        }

        .body-perception-block {
            border-radius: 15px;
            padding: 5px;
            color: @port-gore;
            flex-basis: 30%;
            font-weight: 500;
        }

        .body-relationship-explore-block {
            color: @port-gore;
            border-radius: 15px;
            padding: 10px;
            flex-basis: 48%;
            font-weight: 500;
        }

        .padding-left-large {
            padding-left: 20px;
        }

        .move-left-small {
            position: relative;
            left: -10px;
        }

        .padding-right-small {
            padding-right: 14px;
        }

        .padding-top-medium {
            padding-top: 10px;
        }

        .padding-top-small {
            padding-top: 3px;
        }

        ul.display-two-children > li {
            margin: 10px 0;
        }

        .at-work-content {
            background-color: @color-neutral-50;
            font-size: 16px;
            line-height: 22px;
            margin: 0 36px 30px;
            padding: 6px 14px 16px;
        }

        .title-text {
            font-size: 23px;
            line-height: 30px;
            padding-left: @title-left-padding;
            padding-top: 15px;
            font-weight: 500;
        }

        .playIcon {
            padding-right: 6px;
            font-size: 16px;
            color: @color-primary;
        }

        .banner-layout {
            display: flex;
            padding: 20px 35px;
        }

        .piUsersOnlyLine {
            flex-grow: 1;
            position: relative;
            top: -10px;
        }

        .header-text {
            color: @color-neutral-900;
            font-size: 28px;
            font-weight: 500;
        }

        .banner-text-small {
            font-size: 18px;
            line-height: 25px;
            color: @color-neutral-900;
        }

        .section-header-big {
            font-size: 18px;
            line-height: 33px;
            color: @color-person-700;
            font-weight: 500;
        }

        .section-header-med {
            font-size: 16px;
            line-height: 33px;
        }

        .subtext-small {
            color: @color-neutral-900;
            font-size: 12px;
            line-height: 16px;
        }

        .journeyForward--icon {
            width: 64px;
            padding-right: 10px;
        }

        .button-icon-align {
            padding-left: 64px;
            padding-top: 14px;
        }

        .button-size {
            font-size: 14px;
            padding: 12px 17px;
        }

        .indent-small {
            padding-left: 14px;
        }
    }

    .pdf {
        // README: Because the style needs to be different depending on load case the classes
        // are duplicated. If a class is updated or added, make the changes to both UI and PDF
        // sections of this file.

        // place classes here that are have size-specific elements

        .body-prediction-container {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 0 12px;
        }

        .body-base-container {
            display: flex;
            background-color: @color-person-200;
            justify-content: space-around;
            padding: 8px 35px 25px 24px;
        }

        .reference-pattern-image {
            float: left;
            height: 58px;
            padding: 11px 0 8px 36px;
        }

        .card-subheader {
            font-weight: 500;
            font-size: 16px;
            position: relative;
            left: -8px;
        }
        //    padding-top: 14px;
        .card-content {
            line-height: 26px * @height-ui-to-pdf-multiplier;
            font-size: 14px;
            color: @color-neutral-900;
            padding-bottom: 5px;
        }

        .sub-header-container {
            display: flex;
            background-color: @color-person-200;
            justify-content: flex-start;
            height: 60px;
        }

        .body-heading-candidate-describe {
            padding-left: @title-left-padding;
        }

        .body-heading-explore-pattern {
            font-size: 18px;
            font-weight: 500;
            line-height: 16px * @height-ui-to-pdf-multiplier;
            padding-left: @title-left-padding;
            padding-top: 4px;
        }

        .body-prediction-block {
            background-color: @color-neutral-50;
            padding: 5px;
            padding-left: 20px;
            color: @port-gore;
            width: 23%;
        }

        .body-perception-block {
            border-radius: 15px;
            padding: 5px;
            color: @port-gore;
            flex-basis: 30%;
            font-weight: 500;
        }

        .body-relationship-explore-block {
            color: @port-gore;
            border-radius: 15px;
            padding: 10px;
            flex-basis: 48%;
            font-weight: 500;
        }

        .padding-left-large {
            padding-left: 20px;
        }

        .move-left-small {
            position: relative;
            left: -10px;
        }

        .padding-right-small {
            padding-right: 10px;
        }

        .padding-top-medium {
            padding-top: 10px;
        }

        .padding-top-small {
            padding-top: 3px;
        }

        ul.display-two-children > li {
            margin: 8px 0;
        }

        .at-work-content {
            background-color: @color-neutral-50;
            font-size: 16px;
            line-height: 22px * @height-ui-to-pdf-multiplier;
            margin: 0 20px 18px;
            padding: 4px 14px 10px;
        }

        .title-text {
            font-size: 23px;
            line-height: 30px * @height-ui-to-pdf-multiplier;
            padding-left: @title-left-padding;
            padding-top: 15px;
            font-weight: 500;
        }

        .playIcon {
            padding-right: 6px;
            font-size: 16px;
            color: @color-primary;
        }

        .banner-layout {
            display: flex;
            padding: 10px 22px;
        }

        .piUsersOnlyLine {
            flex-grow: 1;
            position: relative;
            top: -10px;
        }

        .header-text {
            color: @color-neutral-900;
            font-size: 28px;
            font-weight: 500;
        }

        .banner-text-small {
            font-size: 16px;
            line-height: 25px * @height-ui-to-pdf-multiplier;
            color: @color-neutral-900;
        }

        .section-header-big {
            font-size: 18px;
            line-height: 33px * @height-ui-to-pdf-multiplier;
            color: @color-person-700;
            font-weight: 500;
        }

        .section-header-med {
            font-size: 16px;
            line-height: 33px * @height-ui-to-pdf-multiplier;
        }

        .subtext-small {
            color: @color-neutral-900;
            font-size: 12px;
            line-height: 14px;
        }

        .journeyForward--icon {
            width: 54px;
            padding-right: 8px;
        }

        .button-icon-align {
            padding-left: 46px;
            padding-top: 12px;
        }

        .button-size {
            font-size: 14px;
            padding: 5px 15px;
        }

        .indent-small {
            padding-left: 10px;
        }
    }
}
