@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.reportDashboard {
    .reportDashboard_selectionTitle {
        padding: 5px 0;
        font-size: 18px;
        font-weight: 300;
        color: @prestige;
    }

    .reportDashboard_typeahead,
    .reportDashboard_pillbox {
        max-width: 550px;

        input.OVR {
            text-align: left;
        }
    }

    .reportDashboard_buttonContainer {
        justify-content: center;
        margin-top: 40px;
    }
}
