.bulkAssessmentUploadDashboard {
    padding: 0;

    .bulkAssessmentUploadDashboard_content {
        max-width: 800px;
        .flex-display();
        .flex-direction(column);
        .bulkAssessmentUploadDashboard_content_header {
            font-size: 19px;
            font-weight: bold;
            margin-top: 30px;
            label {
                margin-bottom: 0;
                margin-bottom: 3px;
                font-weight: 700;
                font-size: 13px;
                display: flex;

                &[required] {
                    &:after {
                        content: '*';
                        color: @failureRed;
                        margin-left: 3px;
                    }
                }

                // Label with an icon
                i {
                    margin-left: 4px;
                }
            }
        }
        .bulkAssessmentUploadDashboard_content_format {
            font-size: 16px;
        }
    }
}
