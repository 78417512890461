@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.supportInfoBox {
    width: 372px;
    margin-left: 78px;

    .supportInfoBox_container {
        .flex-display();
        .flex-flow(column);
        .align-items(left);
        padding: 30px 30px 20px;

        .supportInfoBox_header {
            font-size: 18px;
            color: @port-gore;
            font-weight: 500;
        }
    }

    &.supportInfoBox--height {
        .supportInfoBox_container {
            max-height: 350px;
        }
    }

    // Handling a specific case where we need multiple supportinfo boxes wrapped in one
    &.supportInfoBox--noBorder {
        padding: 0px;
        margin: 0px;

        .supportInfoBox_container {
            box-shadow: none;
        }
    }

    &.supportInfoBox--evenAlignment {
        padding: 0px;
        margin: 0px;
        .supportInfoBox_container {
            padding: 45px 40px 20px 40px;
        }
        .supportInfoBox_header {
            font-size: 24px;
            font-weight: bold;
        }
        .supportInfoBox_container {
            height: 277px;
            span {
                font-size: 16px;
            }
        }
        .supportInfoBox_footer {
            height: 100%;
            position: relative;
        }
    }

    // Used for specifying the first and last support info boxes when duing multiple in a column
    &.supportInfoBox--stackedFirst {
        .supportInfoBox_container {
            padding-top: 35px;
        }
    }

    &.supportInfoBox--stackedLast {
        .supportInfoBox_container {
            padding-bottom: 35px;
        }
    }
}

.supportInfoBox_info {
    font-size: 14px;
    padding-top: 12px;
    text-align: left;
    padding: 8px 0px 16px 0px;
}

.flatSupportInfoBtn {
    min-width: 300px;
    min-height: 30px;
    font-size: 13px;
    padding: 2px 0px 2px 10px;
    text-align: left;
    font-weight: 500;
    margin: 2px 0px 2px 0px;
    color: @color-primary;
    background: @color-neutral-50;
    border-radius: 0px;

    &:hover {
        background: @color-primary-100;
    }
}

.supportInfoBtn {
    margin-bottom: 13px;
    font-weight: 500;
    text-align: left;
}

.supportInfoBoxSvg {
    width: 18px;
    height: 18px;
    margin: auto;
    margin-right: 5px;
}
