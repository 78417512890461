@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.assessment-details {
    margin-bottom: 37px;

    &:last-child {
        margin-bottom: 13px;
    }

    h5 {
        margin-top: 0;
        border-bottom: 1px solid #e7e3ec;
        padding: 8px 0px;
        font-size: 18px;
    }

    ul {
        list-style: none;
        padding: 0;
    }
    li {
        font-size: 13px;
        margin-bottom: 6px;
        padding-bottom: 4px;
    }
    .key {
        font-weight: 700;
    }
    .value {
        padding-left: 5px;
    }
}
