@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/okapi.less';

.administrationDashboard {
    padding: 25px 25px 0;

    /* All the sub-pages won't take up full width. We'll force them to! */
    .adminstrationDashboard_routerWrapper {
        min-height: calc(
            ~'100vh - 237px'
        ); // Footer, header, section title+subtitle, something else?
        margin: 0 -25px;
        padding: 0 25px;
        background: @concrete;

        > ng-component {
            width: 100%;

            .administrationDashboard_innerContents {
                .flex-display();
                padding: 25px 0;

                .administrationDashboard_innerContents_column {
                    .flex-1();
                }

                .administrationDashboard_innerContents_selectFolder {
                    max-width: 100%;
                    margin-right: 40px;
                    .TransitionProperties(max-width 0.25s ease-out;);

                    &.administrationDashboard_innerContents_selectFolder--editingFolder {
                        max-width: 40%;
                    }
                }

                .administrationDashboard_innerContents_editFolder {
                    max-width: 0%;
                    .TransitionProperties(max-width 0.25s ease-out;);

                    &.administrationDashboard_innerContents_editFolder--editingFolder {
                        max-width: 100%;
                    }

                    card {
                        padding-bottom: 25px;

                        .pi-card .pi-card_content {
                            min-height: 100px;
                        }

                        .administrationDashboard_innerContents_editFolder_row {
                            margin-bottom: 10px;

                            label {
                                display: block;
                            }

                            span {
                                padding: 6px 12px;
                            }
                        }
                    }

                    .administrationDashboard_innerContents_editFolder_actionButtons {
                        margin: 15px 0 25px;
                        text-align: center;
                    }

                    .administrationDashboard_innerContents_addFolder {
                        .flex-display();
                        margin: 10px 0;

                        .administrationDashboard_innerContents_addFolder_text {
                            .flex-1();
                        }

                        .administrationDashboard_innerContents_addFolder_button {
                            width: 26px;
                            line-height: 70px;

                            a {
                                color: @boulder;
                                line-height: 34px;
                                cursor: pointer;

                                &:hover,
                                &:focus,
                                &:active {
                                    color: @silver;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* This is used for cropping the iframe so we
            only see the parts of the panels we care abouut */
        .administrationDashboard_iframeContainer {
            position: absolute;
            height: 750px;
            width: 1360px;
            overflow: hidden;

            .administrationDashboard_iframeContainer_iframe {
                position: relative;
                left: -325px;
                top: -140px;
                border: none;
                overflow: hidden;
                backface-visibility: hidden; // This is for PI-3268, Chrome only so no prefixing needed
            }
        }
    }
}

.tabNavigationList.tabNavigationList--admin {
    width: 1035px;
}

//For new admin menu PI-8598
.tabNavigationList.tabNavigationList--administration {
    margin-top: 30px;
    width: 100%;

    .tab {
        color: @color-primary;
        background: white;
        border: 1px solid @color-primary;
        padding: 10px 25px;
        font-size: 18px;
        margin-right: 10px;

        &:hover {
            border: 1px solid @color-primary;
            background-color: @color-primary-100;
        }
    }

    .disableTab .tab {
        background-color: @color-neutral-100;
        color: @color-disabled-text;
        cursor: not-allowed;
    }

    .activeTab .tab {
        background: @color-primary;
        color: white;

        &:before {
            background: none;
        }

        &:hover {
            border: 1px solid @color-primary;
        }
    }
}

//Compatible with old version, will finally delete this
.adminDashboard_iframeContainer {
    width: 1360px;
    overflow: hidden;
    height: 350px;
}

.adminDashboard_iframeContainer_iframe {
    position: relative;
    left: -353px;
    top: -198px;
    border: none;
    overflow: hidden;
    backface-visibility: hidden;
}

.settingsInputContainer {
    .bg-color-neutral-50();
    border-radius: 6px;
    padding: 30px;

    .form-control {
        background: white;
    }
}

.settingsSubtitle {
    .color-neutral-700();
    .smallText();
    font-style: italic;
}

.adminSection--loading {
    //This seems to be the only way to get the loading dots to
    //stay within the container
    .pi-loadingDots {
        position: relative;
        left: 100%;
    }
}

.usermanagement-toggle-access {
    //make sure tooltip is centered on toggle switch
    .tooltip {
        margin-left: 53px;
    }
}
