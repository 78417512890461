@import '../../../../../node_modules/@predictiveindex/client-core/styles/constants';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors';

.sharingBar_actions_CTA {
    height: 36px;
    width: 36px;
    border-radius: 36px;
    margin-left: 20px;
    background: @electric-violet;
    color: white;
    text-align: center;
    font-size: 20px;
    cursor: pointer;

    &.sharingBar_actions_disabled {
        cursor: auto;
        pointer-events: none;
        background: @boulder;
    }

    .ico-moon.ico-moon-paperplane:before {
        margin-left: -2px;
    }

    &.sharingBar_actions_CTA--languages {
        background: none;
        color: @electric-violet;

        &.sharingBar_actions_disabled {
            cursor: auto;
            pointer-events: none;
            color: @boulder;
        }
    }
}

sharing-bar {
    height: 40px;
    line-height: 36px;
    //width: 100%;

    .sharingBar {
        position: relative;
        width: 100%;
        font-size: 18px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        z-index: 3;

        .sharingBar_actions {
            .flex-display();
            line-height: 36px;

            .ico-moon {
                line-height: 36px;
            }
        }
    }
}

.sharingReportEmail-modal {
    .modal-dialog {
        width: 620px;
    }

    .control-label {
        max-width: none;

        > span {
            margin-top: 10px;
        }
    }
}

.sharingEmail-bottomSection {
    margin-top: -10px;
    margin-bottom: 20px;
}

.sharingEmail-previewDescription {
    color: @color-neutral-800;
    font-style: italic;
}

.sharingEmail-previewLinkStyle {
    color: @kingfisher;
    font-weight: 500;
    font-style: italic;
    text-decoration: underline;
}

.sharingEmail-loadingMessage {
    text-align: right;
    font-style: italic;
    color: @color-neutral-700;
    font-size: 16px;
    padding-top: 20px;
}
