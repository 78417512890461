@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities';

// Align button at bottom
.patternInsights_toggleContainer {
    text-align: center;
}

.patternInsights_cardContainer {
    .flex-display();
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.patternInsights_cardContainer--combination {
    .flex-wrap(wrap);
}

.patternInsights_card {
    .flex-1();
    border: 1px solid @mercury;
    .border-radius(4px);
    margin: 0 10px;
    margin-bottom: 10px;
}

.patternInsights_factorCard {
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

.patternInsights_combinationCard {
    .flex(1 0 calc(~'33% - 20px'));

    &:nth-child(3n + 1) {
        margin-left: 0;
    }
    &:nth-child(3n + 3) {
        margin-right: 0;
    }

    .patternInsights_cardContainer_header_letter {
        font-size: 12px;
    }
}

.patternInsights_cardContainer_header {
    background: @concrete;
    position: relative;
    padding: 5px 10px;
    height: 50px;
    .flex-display();
    line-height: 35px;
}

.patternInsights_cardContainer_header_letter {
    position: absolute;
    // Go over the border
    top: -1px;
    right: -1px;
    background: @person;
    color: white;
    padding: 2px 10px;
    .border-radius(0 4px 0 0);
    font-size: 16px;
}

.patternInsights_cardContainer_header_name {
    color: @boulder;
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
}

.patternInsights_card_behaviorTraits,
.patternInsights_card_needs {
    padding: 10px;

    > div {
        margin-bottom: 5px;
    }
}

.patternInsights_cardContainer_body_descriptor {
    font-weight: 500;
    &.highlight {
        color: @person;
    }
}

.patternInsights_cardContainer_body_subheader {
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
}

// Let the specificity war begin!
.closableCard .pi-card_content.slideShut {
    transition-property: max-height, min-height, padding-bottom, padding-top;
    transition-duration: 0.5s;
    max-height: 2000px;
    &.slideShut--closed {
        max-height: 0px;
        min-height: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        overflow: hidden;
    }
}

.svgLetter {
    height: 35px;
    width: 35px;
    display: inline-block;
}
