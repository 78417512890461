@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/guide.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/input.less';
/*
The following was taken directly from expo. It could be cleaned up a bit.
Many of the classes may not be used
*/

/* ----------------------- */
/* Email Pill Element */
/* ----------------------- */

.pi-pillHolder {
    // This should be done in a more general way
    &.disabled {
        background: @concrete;
        color: @dust;
        border-color: @concrete;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        pointer-events: none;
        cursor: default;
    }

    .inputBorderStyle();

    position: relative;
    background: white;
    .border-radius(5px);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 10px;
    cursor: text;

    &.pi-pillHolder--withAdditionalInfo {
        padding-right: 175px;
    }
    &.pi-pillHolder--hasPills {
        padding-bottom: 0;
    }

    .pi-pillHolder_additionalInfoText {
        position: absolute;
        right: 15px;
        bottom: 10px;
        color: @dust;
        font-style: italic;
    }
}
.pi-pill {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
    margin-bottom: 10px;
    color: @prestige;
    border: 1px solid @silver;
    background: @mercury;
    .border-radius(5px);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.pi-pill_start {
    .border-radius(5px 0 0 5px);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 3px 10px 4px 10px;
    display: inline-block;
    cursor: default;
}
.pi-pill_group {
    .border-radius(5px 0 0 5px);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: @pacific;
    color: white;
    padding: 3px 10px 4px 10px;
    display: inline-block;
}
.pi-pill_edit {
    background: @concrete;
    padding: 3px 10px 4px 10px;
    display: inline-block;
    margin-left: -5px;
    cursor: pointer;
}
.pi-pill_delete {
    .border-radius(0 5px 5px 0);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 3px 10px 4px 10px;
    display: inline-block;
    margin-left: -5px;
    cursor: pointer;
}
.pi-pill_hidden {
    width: 60px;
    background: white;
    color: white;
    cursor: default;
}
.pi-pill_input {
    min-width: 150px;
    // Override default height for all inputs
    height: auto;
    // the outer box should seem like the input
    outline: 0;
    border: none;
    padding: 3px 4px;
    // It might make more sense for this to be a general override to deal with
    // the * { display: block; } rule
    // Overriding here, because I saw the issue here
    display: inline-block;
}

pill {
    display: inline-block;
}

pillbox .dropdownContent {
    position: fixed;
}

people-email-pillbox .pi-pill.pi-pill-invalid {
    background-color: red;
    color: white;
}
people-email-pillbox .ok-typeahead__noResultsMessage {
    display: none;
}
