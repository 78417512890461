label {
    [coachMark] {
        margin-left: 3px;
    }
}

.coach-mark {
    position: absolute;

    .coach-mark-arrow {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 60 55'%3E%3Cg transform='scale(-1 1) rotate(-49 -14.149 72.738)' stroke='%230092C8' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpath d='M5.049 60.665c5.052-7.155 7.972-15.559 8.757-25.212.785-9.652-.982-18.915-5.303-27.788'/%3E%3Cpolyline stroke-linejoin='round' points='3.91259933 10.9083891 5.16113448 3.726016 12.7392595 4.93109413'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 60px;
        height: 55px;
        position: absolute;

        &.right {
            transform: scaleX(1);
            right: 0%;
        }
        &.left {
            transform: scaleX(-1);
            left: 0%;
        }
    }

    .coach-mark-inner {
        color: @color-primary-900;
        background-color: @color-neutral-50;
        padding: 10px;
        width: auto;
        font-size: 16px;
        border-radius: 10px;
        box-shadow: 0 2px 10px 0 rgba(61, 28, 112, 0.5);

        &.top {
            transform: scaleY(-1);
        }
        &.bottom {
            transform: scaleY(-1);
        }
    }

    &.top {
        transform: scaleY(-1);

        .coach-mark-inner {
            transform: scaleY(-1);
        }
        .coach-mark-arrow {
            bottom: 95%;
        }
    }
    &.bottom {
        transform: scaleY(1);

        .coach-mark-arrow {
            bottom: 95%;
        }
    }

    .coach-mark-position {
        position: relative;
    }

    a {
        color: @color-primary;
        font-weight: 500;
        text-decoration: underline;
    }
}
