// This was included for FactorE graph - TODO - we should clean it up

#resizable {
    width: 800px;
    height: 400px;
    padding: 0.5em;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none; /* non-prefixed version, currently
                                  not supported by any browser */
}
