@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

pi-copy-to-clipboard,
inline-open-invitation-link {
    .flex-display();
    .clipboardContainer {
        background: @concrete;
        border: 1px solid @silver;
        border-radius: 5px;
        .flex-display();
        .flex-direction(column);
        .clipboardContainer_content {
            .flex-display();
        }
        .clipboardContainer_title {
            color: white;
            background: @silver;
            display: inline-block;
            padding: 2px 4px;
        }
        .clipboardContainer_text {
            padding: 16px;
            .flex-auto();
            .textBox {
                .box-sizing(border-box);
            }
        }
    }
    .clipboardContainer_buttons {
        padding: 16px;
        margin: auto;
        .copiedMessage {
            color: white;
            background-color: black;
            opacity: 0;
            .TransitionProperties(opacity 0.5s linear;);
            padding: 4px 16px;
            border-radius: 6px;
            margin-right: 12px;
            &.displayMessage {
                opacity: 1;
            }
        }
    }
    .clipboardContainer_inline {
        .flex-auto();
        input {
            padding: 0;
            height: auto;
            margin-top: 6px;
            text-overflow: ellipsis;
        }
    }
}

pi-copy-to-clipboard-button {
    .copiedMessage {
        margin-top: 5px;
        color: @kingfisher;
        opacity: 0;
        .TransitionProperties(opacity 0.5s linear;);
        padding: 4px 16px;
        &.displayMessage {
            opacity: 1;
        }
    }
}
