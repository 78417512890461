@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities';

old-icon {
    display: inline;
}

.clickable-icon {
    cursor: pointer;
    opacity: 0.2;
    &:hover {
        opacity: 1;
    }
}

.smallIcon {
    height: 35px;
    width: 35px;
    font-size: 45px;
}
