.pi-dashboard.coachingGuideDashboard {
    padding-left: 105px;

    .pi-dashboard_subtitle {
        max-width: 925px;
    }
}

coaching-guide-report {
    max-width: 850px;
    width: 100%;

    > div {
        width: 100%;
    }

    .legend-display {
        width: 37px;
        height: 31px;
    }
    .factor-display {
        width: 33px;
        height: 33px;
    }

    .section-header {
        padding-bottom: 15px;
        font-size: 20px;
        color: @jacarta;
        font-weight: bold;
    }
}

.legendPanel {
    background-color: @faded-purple;
    border-radius: 4px;
    padding: 10px;
}

.updatePersonJob {
    padding-top: 20px;
    .flex-display();
    .justify-content(start);
}

.guideGeneratedSuccess {
    color: @vista-green;
    font-size: 15px;
    .justify-content(center);
    padding: 10px;
    .success {
        padding: 14px;
        font-size: 46px;
    }
}

.jobRanges {
    padding-bottom: 14%;
}

.coachingGuidePrint {
    .coachingGuidePrint_personJobBox {
        .flex-display();
        .justify-content(space-between);

        .coachingGuidePrint_personJobBox_person {
            .coachingGuidePrint_personJobBox_person_referencePattern {
                height: 40px;
                margin-right: 2px;
            }
        }

        .coachingGuidePrint_personJobBox_job {
            .coachingGuidePrint_personJobBox_person_briefcase {
                margin-top: 3px;
                font-size: 32px;
                vertical-align: middle;
            }
        }
    }
}
