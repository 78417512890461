@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.text-graph-hover {
    stroke: none;
    fill: #3b2c4c;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 10pt;
    text-transform: uppercase;
}

.active-cursor {
    cursor: pointer;
}
.scaling-svg-container {
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 100%;
    /* override this inline for aspect ratio other than square */
}
.scaling-svg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
.simple-gray-svg-line {
    fill: none;
    stroke: #a6a6a6;
    stroke-width: 2;
}
factor-combinations,
factor-graph,
factor-ensemble {
    // The following powers the ability to switch between trend and invidiaul views
    .histogram.trend {
        .bin-entry {
            display: none;
        }
    }
    .histogram.individual {
        .bar-entry {
            display: none;
        }
    }

    .rail {
        .y-axis-extension {
            .simple-gray-svg-line;
        }
    }
    .y-axis {
        line,
        path {
            .simple-gray-svg-line;
        }
        & > .tick > line {
            stroke: #a6a6a6;
        }
        text {
            fill: #a6a6a6;
        }
    }
    text.percentage {
        fill: #262626;
        stroke: none;
        text-anchor: start;
    }
    .bin text {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    text.graph-title {
        fill: #262626;
        text-anchor: middle;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14pt;
        text-transform: uppercase;
        cursor: default;
    }
    tspan.graph-title-primary {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
    }
}

factor-ensemble {
    .four-factor-line {
        fill: none;
        stroke: black;
        stroke-dasharray: 6, 6;
        stroke-width: 3;
        &.average {
            stroke: #614a7b;
        }
        &.job {
            stroke: #f26d25;
        }
        &.make-solid {
            stroke-dasharray: none;
        }
    }
    .ensemble-side-label {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 22px;
    }
}

// The following should be a variable in order to match text in box
factor-combinations {
    text.percentage {
        font-size: 6pt;
    }
}
factor-graph,
factor-ensemble {
    .averageMarker {
        circle {
            stroke: #614a7b;
            fill: #d1d3d4; // same as rail, to prevent overlap issues
        }
        path {
            fill: #614a7b;
        }
    }
    .jobMarker {
        circle {
            stroke: #f26d25;
            fill: #d1d3d4; // same as rail, to prevent overlap issues
        }
        .fa {
            fill: #f26d25;
            //dominant-baseline: middle; // does not work in IE
            text-anchor: middle;
            font-size: 13px;
            cursor: default;
        }
    }
    .jobRangeBox {
        fill: #f26d25;
        opacity: 0.4;
    }
    .midpointMarker {
        fill: #231f20;
    }
    .marker-lead {
        stroke-width: 2;
        fill: none;
        stroke-dasharray: 2, 2;
        &.job {
            stroke: #f26d25;
        }
        &.average {
            stroke: #614a7b;
        }
    }
    .railbar {
        stroke: #d1d3d4;
        fill: #d1d3d4;
        stroke-width: 0.5;
    }
    .average-detail {
        rect {
            fill: #f4f4f4;
            stroke-dasharray: 2, 2;
            stroke-width: 1;
        }
        &.average {
            rect {
                stroke: #614a7b;
            }
        }
        &.job {
            rect {
                stroke: #f26d25;
            }
        }
    }
    text.percentage {
        font-size: 8pt;
    }

    .x-axis {
        // This stuff is intended to match the lower labels of the factor combinations chart
        .tick text {
            text-transform: capitalize;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 8pt;
            cursor: default;
            fill: #12a29d;
        }
    }
}

// The following CSS is specifically to override the above. The all factors ensemble is smaller so the briefcase must
// shrink to fit
factor-ensemble {
    .jobMarker {
        .fa {
            font-size: 10px;
        }
    }
}

factor-combinations {
    .factor-combinations-graph {
        stroke: none;
        stroke-width: 0.5;
        fill: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 10pt;
    }

    /*.y-axis path,
    .y-axis line {
        fill: none;
        stroke: #a6a6a6;
        stroke-width: 2;
    }
    .y-axis text {
        fill: #a6a6a6;
    }*/
    .x-axis-line line {
        fill: none;
        stroke: #262626;
        stroke-width: 3;
    }
    .x-axis-label {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        fill: #262626;
        font-size: 10pt;
        text-anchor: middle;
    }
    .tick-tooltip {
        stroke: none;
        fill: #a6a6a6;
        text-anchor: middle;
    }
    .tick-tooltip.primary {
        fill: #3b2c4c;
    }
    .tick {
        fill: black;
        text-anchor: middle;
        font-size: 8pt;
    }
    .tick > line {
        stroke: black;
    }
    /*.y-axis > .tick > line {
        stroke: #a6a6a6;
    }*/
    .tick > path.tab {
        fill: @situationally;
        stroke: black;
        stroke-width: 0.5;
    }
    .tick.hover > path.tab {
        fill: #3b2c4c;
        stroke: #3b2c4c;
        stroke-width: 1;
    }
    .tick.secondary-hover > path.tab {
        fill: #e9e1ef;
        stroke: #3b2c4c;
        stroke-width: 1;
    }
    .tick.hover > text,
    .tick.hover > line {
        stroke: white;
        fill: white;
    }
    /*g.bin text.percentage {
        fill: #262626;
        stroke: none;
        text-anchor: start;
        font-size: 6pt;
    }*/
    g.bin .bin-entry rect.border {
        fill: none;
        stroke: #262626;
        stroke-width: 0.5;
    }
    g.bin .bin-entry.highlight rect.border {
        fill: #fcb534;
    }
    g.bin .bin-entry text {
        fill: #262626;
        font-size: 6pt;
        text-anchor: middle;
        stroke-width: 0;
    }
    g.bin.hover g.bin-entry text {
    }
    g.bin.hover g.bin-entry rect.border,
    g.bin.secondary-hover g.bin-entry rect.border {
        stroke: #3b2c4c;
        stroke-width: 1;
    }
    .bin-entry.extremely rect.intensity {
        fill: @extremely;
    }
    g.bin.hover g.bin-entry.extremely rect.border,
    g.bin-entry.extremely.hover rect.border {
        fill: @extremely;
    }
    .bin-entry.moderately rect.intensity {
        fill: @very;
    }
    g.bin.hover g.bin-entry.moderately rect.border,
    g.bin-entry.moderately.hover rect.border {
        fill: @very;
    }
    .bin-entry.slightly rect.intensity {
        fill: @moderately;
    }
    g.bin.hover g.bin-entry.slightly rect.border,
    g.bin-entry.slightly.hover rect.border {
        fill: @moderately;
    }
    .bin-entry.neutral rect.intensity {
        fill: @situationally;
    }
    g.bin.hover g.bin-entry.neutral rect.border,
    g.bin-entry.neutral.hover rect.border {
        fill: @situationally;
    }
    g.bin.hover g.bin-entry.extremely text,
    g.bin.hover g.bin-entry.moderately text,
    g.bin-entry.extremely.hover text,
    g.bin-entry.moderately.hover text {
        fill: white;
    }
    .bar-entry .neutral {
        fill: @situationally;
    }
    .bar-entry .slightly {
        fill: @moderately;
    }
    .bar-entry .moderately {
        fill: @very;
    }
    .bar-entry .extremely {
        fill: @extremely;
    }
}
/*
I moved this here because I would slowly like to unify it with the graph above.
*/
factor-graph,
factor-ensemble {
    .x-axis {
        text {
            fill: black;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 10pt;
        }
    }
    /*.y-axis path,
    .y-axis line {
        fill: none;
        stroke: black;
        stroke-width: 0.5;
    }*/
    .tick-tooltip {
        stroke: none;
        fill: black;
        text-anchor: middle;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 10pt;
    }
    .tick {
        stroke: none;
        fill: #a6a6a6;
        text-anchor: middle;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 8pt;
    }
    .tick.hover {
        font-weight: bold;
    }
    g.bin .bin-entry rect.border {
        fill: none;
        stroke: black;
        stroke-width: 0.5;
    }
    g.bin .bin-entry.highlight rect.border {
        fill: #fcb534;
    }
    g.bin .bin-entry text {
        fill: black;
        //font-family: sans-serif;
        font-size: 8pt;
        text-anchor: middle;
        stroke: none;
    }
    g.bin.Very rect.intensity {
        fill: @extremely;
    }
    g.bin.Very.hover rect.border,
    g.bin.Very g.bin-entry.hover rect.border {
        fill: @extremely;
    }
    g.bin.Moderately rect.intensity {
        fill: @very;
    }
    g.bin.Moderately.hover g.bin-entry rect.border,
    g.bin.Moderately g.bin-entry.hover rect.border {
        fill: @very;
    }
    g.bin.Somewhat rect.intensity {
        fill: @moderately;
    }
    g.bin.Somewhat.hover g.bin-entry rect.border,
    g.bin.Somewhat g.bin-entry.hover rect.border {
        fill: @moderately;
    }
    .bin.Situational rect.intensity {
        fill: @situationally;
    }
    g.bin.Situational.hover rect.border,
    g.bin.Situational g.bin-entry.hover rect.border {
        fill: @situationally;
    }
    g.bin.Very.hover text,
    g.bin.Moderately.hover text,
    g.bin.Very g.bin-entry.hover text,
    g.bin.Moderately g.bin-entry.hover text {
        fill: white;
    }
    g.average-detail text {
        stroke: none;
        fill: #555555;
        text-anchor: middle;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 12pt;
    }
}
