@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/fonts.less';

@tileMargin: 40px;
@tileBoxMarginTop: 10px;

.tileBox {
    .flex-display();
    .flex-wrap(wrap);
    margin-left: -@tileMargin;
    margin-top: -@tileMargin + @tileBoxMarginTop;
}

.tilebox_tile {
    // margin and width are coupled to tileSize in code
    width: 205px;
    height: 145px;
    background: @concrete; // replace with variable
    margin-top: @tileMargin;
    margin-left: @tileMargin;
}
.tilebox_tile.tilebox_tile--coloredBottom {
    border-bottom: 4px solid @kingfisher;
}

.pagerBox {
    height: 35px;
    width: 35px;
    border: 2px solid @boulder;
    z-index: 2;
}
.pagerBox--disabled {
    color: @silver;
    border-color: @silver;
    z-index: 1;
}

@filterInputHeight: 45px;

.simpleInput {
    .mediumText;
    line-height: @filterInputHeight;
    height: @filterInputHeight;
    border: 1px solid @jacarta;
    &:placeholder-shown {
        font-style: italic;
    }
    &:hover {
        border: 1px solid @jacarta;
    }
}

.makeSimpleInput input {
    .simpleInput;
}

.simpleInput_wrapper--searchIcon {
    position: relative;
    &:before {
        .font-awesome-icon(@fa-var-search);
        color: @jacarta;
        opacity: 0.7;
        position: absolute;
        right: 10px;
        font-size: 24px;
        line-height: @filterInputHeight;
    }
}

.simpleTile {
    width: 240px;
    text-align: center;
    margin: 15px;
    padding: 15px;
    border-radius: 5px;
    position: relative;
}

.groupTile_linkText {
    margin-top: 5px;
    color: @kingfisher;
}

.GroupTile_footer {
    margin-top: auto;
    background-color: @kingfisher;
    color: white;
    width: 100%;
    text-align: left;
    padding: 2px 0px 2px 5px;
    font-size: 13px;
}

.sharedGroupTile_linkText {
    margin-top: 5px;
    color: @color-person;
}

.SharedGroupTile_footer {
    margin-top: auto;
    background-color: @color-person;
    color: white;
    width: 100%;
    text-align: left;
    padding: 2px 0px 2px 5px;
    font-size: 13px;
}

.groupCard__count {
    color: @person;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}
