@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/constants.less';

.bulletBox {
    margin-bottom: 15px;

    .bulletBox_header {
        line-height: 48px;
        font-size: 22px;
        margin-bottom: 14px;

        .bulletBox_header_bullet {
            display: inline-block;
            background: @concrete;
            width: 6px;
            height: 28px;
            margin-right: 10px;
        }

        .ico-moon {
            display: inline-block;
            color: @jacarta;
            font-size: 23px;
            cursor: pointer;
            .TransitionProperties(
                transform ease-out @standardTransition,
                color ease-out @standardTransition;
            );
        }
    }

    .bulletBox_bulletList {
        margin-left: 18px;
        max-height: 0;
        overflow: hidden;
        .TransitionProperties(max-height ease-out @standardTransition;);

        ul {
            list-style: none;
            padding: 26px 26px 0;
            font-size: 16px;

            li {
                margin-bottom: 26px;
            }
        }
    }

    &.bulletBox-expanded {
        .bulletBox_header .ico-moon {
            color: @silver;
            .TransformProperties(rotate(180deg) ;);

            .TransitionProperties(
                transform ease-in @standardTransition,
                color ease-in @standardTransition;
            );
        }

        .bulletBox_bulletList {
            max-height: 800px;

            .TransitionProperties(max-height ease-in @standardTransition;);
        }
    }

    &.bulletBox-green {
        .bulletBox_header .bulletBox_header_bullet {
            background: @puerto-rico;
        }

        .bulletBox_bulletList {
            background: @clear-day;
        }
    }
    &.bulletBox-orange {
        .bulletBox_header .bulletBox_header_bullet {
            background: @sandy-brown;
        }

        .bulletBox_bulletList {
            background: @sauvignon;
        }
    }
    &.bulletBox-blue {
        .bulletBox_header .bulletBox_header_bullet {
            background: @cerulean;
        }

        .bulletBox_bulletList {
            background: @foam;
        }
    }
}
