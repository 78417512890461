@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
.fileUpload {
    .drop-zone {
        height: 280px;
        border: 2px dashed rgba(80, 0, 168, 0.31);
        .border-radius();
        max-width: 800px;
        background-color: @color-neutral-50;
        margin: 20px 0px;
        .flex-display();
        .flex-direction(column);
        .justify-content(center);
        padding: 18px;
        .placeholder {
            text-align: center;
            font-size: 18px;
            color: black;
            .fileName {
                font-size: 20px;
                color: @kingfisher;
            }
            .buttons {
                .flex-display();
                .justify-content(space-between);
                padding: 24px 0px;
                margin: 0 auto;
                width: 300px;
            }
            .uploadIcon {
                font-size: 34px;
                color: @kingfisher;
            }
            .custom-file-upload {
                cursor: pointer;
            }
            .placeholderText {
                padding: 8px 0px;
            }
        }
        &.file-over {
            opacity: 0.3;
            background-color: @color-neutral-50;
            border: none;
        }
        &.file-ready,
        &.successfulUpload {
            background-color: @color-neutral-50;
        }
        &.file-error {
            background-color: @faded-torch;
            border: none;
        }
    }
}

.infoMessage {
    .flex-display();
    font-size: 18px;
    color: @jacarta;
    text-align: center;
    padding: 16px 70px;
    &.errorMessage {
        padding: 0px;
        text-align: left;
    }
}

.fileLinks {
    color: @kingfisher;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: @electric-violet;
    }
}

.flex-display {
    .flex-display();
}

.failure-icon-color {
    color: @torch;
}
