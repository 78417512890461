.orgUploadDashboard {
    .orgUploadDashboard_content {
        .flex-display();
        .flex-direction(column);
        .orgUploadDashboard_content_header {
            font-size: 24px;
            font-weight: 500;
        }
        .orgUploadDashboard_content_format {
            font-size: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .orgUploadDashboard_content_format-uploadText {
            color: @color-neutral-700;
            padding-bottom: 10px;
            i {
                color: @color-primary;
            }
        }
        .orgUploadDashboard_engage_upload_format {
            background-color: @color-primary-100;
            border-radius: 6px;
            font-size: 16px;
            max-width: 800px;
            padding: 20px 30px 20px 0px;
        }
        .orgUploadDashboard_engage_upload_header {
            font-size: 19px;
            font-weight: bold;
            line-height: 24px;
            padding-bottom: 10px;
            padding-left: 20px;
        }
        .orgUploadDashboard_engage_upload_format-content {
            padding-left: 54px;
        }
        .orgUploadDashboard_engage_upload_footer {
            padding-top: 10px;
            font-size: 13px;
            font-style: italic;
        }
    }
}

.orgUpload_icon_info {
    color: @color-primary;
    font-size: 24px;
    position: relative;
    top: 3px;
    padding-right: 5px;
}
