@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.pi-list {
    display: block;
    width: 100%;
    font-size: 13px;
    color: @prestige;

    .pi-list_row {
        width: 100%;
        .flex-display();
        .flex-direction(row);
        padding: 8px 0;
        line-height: 18px;
        border-bottom: 1px solid @mercury;
        cursor: pointer;

        &.pi-list_row--header {
            font-weight: bold;
            font-size: 12px;
            cursor: default;
        }

        &.pi-list_row--selected {
            background: @jacarta;
            color: white;
        }

        > div {
            .flex(1 1 0);
            padding: 0 8px;
        }
    }

    &.pi-list--selectBenchmark {
        position: relative;
        .pi-list_row {
            > div:nth-child(2) {
                text-align: right;
            }
        }
    }
}

ul.listStyling {
    list-style: disc;
    padding-left: 40px;
}

ul.textAnswer {
    margin-left: 18px;
    list-style-type: disc;
    list-style-position: outside;
    color: @color-primary-400;
}
