@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/constants.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.cognitiveBanner {
    display: table;
    height: @cognitive-banner-height;
    background: @sky;
    width: 100%;
    text-align: center;
    .cognitiveBanner-bannerText {
        font-weight: bold;
        font-size: 15px;
        color: black;
        vertical-align: middle;
        display: table-cell;
    }
}
