@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

tab-navigator {
    .flex-display();
}

.tabNavigationList {
    .flex-display();
    list-style: none;
    margin-bottom: -1px;

    .tab {
        display: inline-block;
        background: white;
        padding: 8px 20px;
        margin-right: 2px;
        .border-radius(4px 4px 0 0);
        border: 1px solid @silver;
        cursor: pointer;
        font-size: 13px;
        position: relative;

        &:not(.disableTab) {
            &:hover,
            &:active,
            &:focus {
                background: @concrete;
                border-left-color: transparent;
                border-top-color: transparent;
                border-right-color: transparent;
            }
        }

        &.disableTab {
            background: @silver;
            opacity: 0.6;
            cursor: default;
        }
    }
    .activeTab .tab,
    .tab.activeTab {
        background: @concrete;
        border-bottom: 1px solid @concrete;
        font-weight: 500;

        &:before {
            content: ' ';
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            height: 5px;
            background: @jacarta;
            .border-radius(4px 4px 0 0);
        }

        &:hover,
        &:active,
        &:focus {
            background: @concrete;
            border: 1px solid @silver;
            border-bottom: 1px solid @concrete;
        }
    }

    &.tabNavigationList--whiteBodyUnderneath {
        .activeTab .tab {
            border-bottom: 1px solid white;
        }
    }
}

.tabNavigationList.tabNavigationList--job,
.bulkActionTable--job .tabNavigationList {
    .activeTab .tab:before {
        background: @job;
    }
}
.tabNavigationList.tabNavigationList--person,
.bulkActionTable--person .tabNavigationList,
.tabNavigationList.tabNavigationList--assessPeople {
    .activeTab .tab:before {
        background: @person;
    }
    .tabContainers {
        text-align: center;
        width: 170px;
    }

    .tabImages {
        width: 40px;
    }
}
.tabNavigationList.tabNavigationList--disabled,
.bulkActionTable--disabled .tabNavigationList {
    .activeTab .tab:before {
        background: @prestige;
    }
}
