@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/variables.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.jobCard {
    margin-top: 5px;
    max-width: 60%;
    .flex-grow(10);
    .jobCard__title {
        font-size: 18px;
        font-weight: 500;
        width: fit-content;
        padding-bottom: 2px;
    }
    .jobCard__lastModified {
        font-size: 13px;
        font-weight: 400;
        color: @color-neutral-700;
    }
}

.jobCenter__table {
    tr.pi-table_row {
        line-height: unset;
    }
}
