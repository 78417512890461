@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/constants.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.acceptJobTargetPage .behavioralTargetCard {
    .flex-basis(200px);
}

.desktop(
    {.acceptJobTargetPage .behavioralTargetCard {.flex-basis(0) ; width: auto;}}
);

.acceptJobTargetPage,
.modifyJobTargetPage {
    .flex-wrap(wrap);

    modify-job-target {
        .flex-auto();
        margin: 0 -@dashboardGutter / 2;
    }

    .pi-dashboard_title {
        color: @job;
    }

    card {
        margin: 0 @dashboardGutter / 2;
        margin-bottom: @dashboardGutter;
        .flex(1 0 0);
    }
    .behavioralTargetCard {
        .flex-grow(2);
    }
    .acceptJobTargetPage_buttonContainer {
        .flex-grow(3);
        .flex-basis(100%);
        .flex-display();
        .justify-content(center);
        margin: 40px 0;
        .btn--lsg {
            margin: 0 10px;
        }
    }
}

set-job-dashboard-targeting-choice {
    width: 33%;
    .flex-auto();
    .flex-display();
    margin-right: 30px;
    &:last-child {
        margin-right: 0;
    }

    .setJobDashboard_targetingChoice_button {
        .flex-auto();
        text-transform: unset;
        white-space: normal;

        &.setJobDashboard_targetingChoice_button--grayBack {
            background: @concrete;
        }

        .setJobDashboard_targetingChoice_button_icon {
            width: 65px;
            height: 50px;
        }

        .setJobDashboard_targetingChoice_button_icon--thin {
            width: 22px;
            height: 45px;
        }

        .setJobDashboard_targetingChoice_button_mainText {
            font-weight: bold;
            font-size: 20px;
            color: @smoky;
            margin-bottom: 10px;
        }

        .setJobDashboard_targetingChoice_button_subText {
            font-size: 13px;
        }
    }
}

.setJobDashboard_successNavigation {
    .flex-display();
    .justify-content(center);

    .setJobDashboard_successNavigation_item {
        .flex-display();
        .flex-direction(column);
        .justify-content(space-evenly);
        width: 170px;
        height: 108px;
        padding: 0 12px;
        font-size: 14px;
        border-radius: 8px;

        .fa {
            display: block;
            font-size: 36px;
        }

        span {
            // text displayed
            white-space: pre-line;
        }
    }
}
