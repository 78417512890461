@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/text.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

// @page properties only apply to print PDF version of BA report
@page {
    size: A4;
    //If this margin is too large, content will be cut off from print display
    margin: 40px 0;
}
@page:first {
    margin-top: 30px;
}

.pi-logo-red-with-name {
    width: 142px;
    height: 60px;
}

behavioral-report {
    behavioral-ranges {
        margin-bottom: 10px;
    }
}

report-preview-action {
    background-color: @concrete;
}

.behavioralReport_wrapper {
    max-width: 800px;
    padding: 5px 30px;
    background: white;
    margin: 0 auto;
    font-size: 15px;
}

.behavioralPattern_wrapper {
    max-width: 700px;
    padding: 5px 30px;
    background: white;
    margin: 0 auto;
    font-size: 15px;
}

.jobTargetProfile_sectionTitle,
.targetReferenceProfile_title,
.targetReferenceProfile_subtitle,
.jobTargetProfile_cognitiveSectionTitle {
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
}

.behavioralReport_reportTitle {
    font-weight: 300;
}

.behavioralReport_header {
    .flex-display();
    .justify-content(space-between);
    margin: 5px 0 15px 0;
    padding: 10px 0 5px 0;
    border-bottom: 2px solid;
}

.behavioralReport_personName {
    margin: 0;
}

.behavioralReport_headerDates {
    width: 33%;
    .flex-display();
    .justify-content(space-between);
}

.behavioralReport_dateTitles {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.behavioralReport_dates {
    text-align: right;
    font-size: 14px;
    margin: 0;
}

.behavioralReport_patternSection {
    margin: 20px;
    // Page break after pattern section for PDF version
    break-after: page;
}

.behavioralReport_referenceProfile {
    border-bottom: 1px solid;
}

.baReport_sectionTitle {
    font-size: 18px;
    border-bottom: 1px solid;
    border-color: @mineshaft;
    padding-bottom: 3px;
    color: @color-brand-red;
    font-weight: 400;
}

.baReport_sectionIntroBold {
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.baReport_sectionIntro {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.baReport_sectionBody {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

    // Avoids cutting paragraphs at bottom of page if
    // there are <= 4 lines before cut off in PDF
    p {
        orphans: 4;
    }
}

.baReport_sectionBodyList {
    font-size: 15px;
    margin-bottom: 20px;
    margin-left: 40px;
}

.baReport_listStyle {
    list-style-type: disc;
    list-style-position: outside;
}

.baReport_copyright {
    text-align: center;
    font-size: 12px;
}

target-reference-profile {
    .flex-display();
}

@iconSize: 70px;

.targetReferenceProfile_wrapper {
    .flex-display();
    margin-right: 15px;
}

.targetReferenceProfile_badge {
    margin-top: 5px;
    margin-right: 10px;
    width: @iconSize;
    height: @iconSize;
}

.targetReferenceProfile_title {
    margin-top: 5px;
    text-decoration: underline;
    font-weight: normal;
}

.targetReferenceProfile_subtitle {
    background-color: @mercury;
    padding: 8px 4px;
}

.cogTarget_text {
    .flex-auto();
}

.jobCharacteristics_list {
    list-style-type: disc;
    list-style-position: inside;
}
