@import 'factorCombinations.less';
@import 'factorEGraph.less';
@import 'legend.less';

analytics-controls {
    position: relative;
    margin-top: 30px;
}

.analyticsControlSection {
    display: block;
    vertical-align: middle;
    margin: 9px 13px;
    > * {
        display: block;
        vertical-align: middle;
    }
}

.analyticsControlSection--title {
    margin: 16px 0 8px;
    font-weight: 500;
}

pillbox.noEdit {
    .pi-pill_edit {
        display: none;
    }
}
