place-near {
    display: inline;
    org-upload-required-columns {
        border: @cold-purple solid 1px;
    }
    .closeBar {
        .flex-display();
        .flex-direction(row-reverse);
    }
    .custom-column-info {
        color: @kingfisher;
        padding: 10px;
        font-size: 14px;
        div {
            border-top: @color-neutral-400 1px solid;
            padding-top: 10px;
        }
    }
    .requiredFields {
        .flex-display();
        .justify-content(space-between);
        margin-top: 13px;
        padding: 0px 28px 12px 28px;
        .column-header {
            color: @color-primary;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            border-bottom: @color-primary-400 solid 5px;
            margin-right: 25px;
            margin-bottom: 10px;
        }
        .optional-column-header {
            color: @color-primary-300;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            border-bottom: @color-primary-300 solid 5px;
            margin-right: 25px;
            margin-bottom: 10px;
        }
        .optional {
            color: @color-primary-300;
        }
        ul > li {
            padding: 4px 0px;

            div {
                font-style: italic;
                font-size: 14px;
                width: 200px;
            }
        }
    }
    .icon-colorPurple {
        color: @electric-violet;
    }
}
