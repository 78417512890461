@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

@toggleBorderRadius: 5px;

.pi-favorite-pass-toggle {
    .flex-display();

    button {
        border: 1px solid @mercury;
        background: white;
        font-size: 18px;
        .flex(0 1 40px);
        height: 28px;
        color: @silver;
        .flex-display();

        i {
            .flex-auto();
            align-self: center;
        }

        &:first-child {
            .border-radiuses(0, 0, @toggleBorderRadius, @toggleBorderRadius);
            border-right: 0;
        }
        &:last-child {
            .border-radiuses(@toggleBorderRadius, @toggleBorderRadius, 0, 0);
        }

        &[disabled] {
            opacity: 0.75;
            cursor: not-allowed;
        }
    }

    .fa-heart.selected {
        color: @favorite-heart;
    }
    .fa-ban.selected {
        color: @torch;
    }
}
