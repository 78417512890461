@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.relationshipGuideReport {
    .relationshipGuideReport_header {
        .flex-direction(row);
        .justify-content(space-between);
        font-size: 24px;
        font-weight: bold;
        .relationshipGuideReport_header_icon {
            padding-right: 5px;
            font-size: 19px;
        }
        .relationshipGuideReport_header_tagLine {
            font-size: 17px;
            font-weight: normal;
            margin-left: 34px;
        }
    }
    .relationshipGuideReport_peopleInfo {
        background-color: @foam;
        padding: 8px 24px;
        .flex-display();
        .justify-content(space-between);
        .referenceProfileInfo {
            .referenceProfileInfo_container {
                line-height: normal;
                .referenceProfileInfo_title {
                    font-size: 17px;
                }
                .referenceProfileInfo_container_details {
                    font-size: 14px;
                }
            }
        }
    }

    .relationshipGuideReport_relationshipDetails {
        padding: 10px 36px;
        .bulletBox {
            .bulletBox_header {
                font-size: 16px;
                margin-bottom: 5px;
                font-weight: bold;
                .bulletBox_header_bullet {
                    display: none;
                }
                .headerIcon {
                    .TransformProperties(rotate(0deg) ;);
                    font-size: 13px;
                    padding: 4px;
                    color: white;
                    border-radius: 13px;
                    margin-right: 4px;
                    &.headerIcon--strengths {
                        background-color: @puerto-rico;
                    }
                    &.headerIcon--cautions {
                        background-color: @sandy-brown;
                    }
                    &.headerIcon--tips {
                        background-color: @picton;
                    }
                }
                .ico-moon-circle-down2 {
                    display: none;
                }
            }
            .bulletBox_bulletList {
                border-bottom: solid 3px @concrete;
                ul {
                    padding: 0px 10px;
                    font-size: 14px;
                    li {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        bullet-box:last-of-type .bulletBox_bulletList {
            border-bottom: none;
        }
    }
}
