// This is how we get SVGs to also properly scale to their proper container in IE11
// Source: https://css-tricks.com/scale-svg/
// See "Option 4: Use the padding-bottom Hack on a Container"
.scalingSvgContainer {
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    // over-ride this inline for aspect ratio other than square
    padding-bottom: 100%;

    .scalingSvg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }
}
