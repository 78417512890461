.jobDrillDown_outerContainer {
    border: 1px solid @port-gore;
    .border-radius(10px);
    padding: 10px;
    text-align: left;
}

.jobDrillDown_backButtonContainer {
    position: absolute;
    padding-right: 10px;
    margin-top: -6px;

    .jobDrillDown_backButton {
        font-size: 15px;
    }
}

.tinyReferenceIcon img {
    width: 35px;
    margin-right: 10px;
}

.jobDrillDown_left {
    font-size: 14px;
    .flex(0 1 400px);
    .flex-display();
    .flex-direction(column);
    border-right: 1px solid @port-gore;
    padding-right: 10px;

    .jobDrillDown_left_bottomContent {
        > div {
            // This is to account for the size of the ref. prof. image that's inlined
            line-height: 40px;
            margin: 10px 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .jobDrillDown_statusItem_title {
        font-weight: bold;
        margin-right: 4px;
    }
    .jobDrillDown_statusItem_text {
        font-style: italic;
    }
    old-icon img {
        width: 35px;
        margin-right: 10px;
    }
    ul {
        .flex-display();
    }
    .jobDrillDown_recentCandidates {
        .flex-display();
        .jobDrillDown_statusItem_title {
            margin: auto 0;
            margin-right: 4px;
        }
        li {
            cursor: pointer;
        }
    }
}

.lightSide-tooltip {
    .tooltip-inner {
        background: white;
        border: 1px solid @cruise;
        color: black;
    }
    .tooltip.top .tooltip-arrow {
        border-top-color: @cruise;
    }
}

.jobDrillDown_right {
    .flex-auto();
    padding-left: 10px;
    > div {
        line-height: 40px;
        padding: 0px 7px;
    }
    > div:nth-child(even) {
        background: rgba(122, 98, 150, 0.1);
    }
    button {
        margin-right: 6px;
    }
}
