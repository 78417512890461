@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.contactSupport {
    .contactSupport-catalystHeader {
        display: block;
        width: 100%;
        text-align: center;

        .contactSupport-catalystIcon {
            width: 225px;
            height: 60px;
        }
    }

    .contactSupport-iconOptionsList,
    .contactSupport-secondaryOptionsList {
        .flex-display();
        width: 90%;
        margin: 20px 5% 40px;

        .contactSupport-iconOptionsList-option,
        .contactSupport-secondaryOptionsList-option {
            .flex-1();
            .justify-content(space-between);
            max-width: 33%;
            padding: 0 40px;
        }
    }

    .contactSupport-iconOptionsList {
        text-align: center;

        .contactSupport-iconOptionsList-title {
            font-size: 19px;
            font-weight: 500;
        }
        .contactSupport-iconOptionsList-icon {
            height: 175px;
            width: 175px;
        }
        .contactSupport-iconOptionsList-description {
        }
    }

    .contactSupport-secondaryOptionsList {
        .contactSupport-secondaryOptionsList-title {
            font-size: 14px;
            color: @puerto-rico;
            margin-bottom: 4px;
        }
        .contactSupport-secondaryOptionsList-description,
        .contactSupport-secondaryOptionsList-link {
            margin-bottom: 10px;

            .fa {
                margin-right: 4px;
            }
        }
    }
}
