@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/okapi.less';

.tabNavigationList.tabNavigationList--organization {
    margin-top: 30px;
    width: 100%;

    .tab {
        color: @color-primary;
        background: white;
        border: 1px solid @color-primary;
        padding: 10px 25px;
        font-size: 18px;
        margin-right: 10px;

        &:hover {
            border: 1px solid @color-primary;
            background-color: @color-primary-100;
        }
    }

    .disableTab .tab {
        background-color: @color-neutral-100;
        color: @color-disabled-text;
        cursor: not-allowed;
    }

    .activeTab .tab {
        background: @color-primary;
        color: white;

        &:before {
            background: none;
        }

        &:hover {
            border: 1px solid @color-primary;
        }
    }

    &:first-child {
        margin-left: 130px;
    }
}

@tabContainerWidth: 790px;

.aboveTabContainer {
    width: @tabContainerWidth;
}

.tab-container {
    border-top: 5px solid @color-primary;
    background-color: @color-neutral-50;
}

.linkBox {
    a {
        .color-primary;
        font-weight: 500;
    }
    .bg-color-neutral-50;
    padding: 10px;
    .border-radius(3px);
    margin: 4px 0px;
}
