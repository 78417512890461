.jobPipelineHealth_wrapper {
    .flex-display();
    height: 20px;
}

.jobPipelineHealth_entry {
    overflow: hidden;
    border-bottom: 4px solid transparent;
    text-align: left;
    font-size: 12px;
    font-weight: bold;

    span {
        line-height: 1;
        vertical-align: top;
        &:after {
            .font-awesome-icon(@fa-var-user);
            font-size: 12px;
            vertical-align: top;
            margin-left: 2px;
            opacity: 0.7;
        }
    }

    &.Great {
        color: @matchGreat;
        border-color: @matchGreat;
    }
    &.Ok {
        color: @matchOk;
        border-color: @matchOk;
    }
    &.Low {
        color: @matchLow;
        border-color: @matchLow;
    }
    &.Pending {
        color: @matchPending;
        border-color: @matchPending;
    }
}
