@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

onboarding-email-component-selector {
    .emailBody {
        width: 934px;
        padding: 29px 24px 10px 24px;
        text-align: start;
        color: @mineshaft;
        background-color: #fafeff;
        .textArea {
            margin-top: 10px;
            background-color: inherit;
            color: inherit;
            height: 600px;
            outline: none;
            resize: none;
            padding: unset;
        }
        //Bring the CTAs into view upon copying the email link
        .textArea--collapsed {
            height: 180px;
            overflow: hidden;
        }
    }

    .vertical-align {
        display: inline-block;
        vertical-align: middle;
        line-height: 33px;
    }

    .button-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        margin: 0px 9px;

        &.send {
            color: @kingfisher;
        }

        &.copy {
            color: white;
        }
    }

    .center-align {
        display: flex;
        justify-content: center;
    }

    .progress-oval {
        border-radius: 50%;
        width: 20px;
        height: 20px;

        &.done {
            background-color: @spray;
            border: 0;
        }

        &.next {
            background-color: @sail;
        }

        &.move-right {
            position: relative;
            right: -40px;
        }

        &.move-left {
            position: relative;
            left: -40px;
        }
    }

    .icon-padding {
        padding-right: 7px;
        padding-left: 10px;
    }

    .link {
        color: @kingfisher;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
    }

    .padding-top-4 {
        padding-top: 35px;
    }

    .padding-top-5 {
        padding-top: 67.5px;
    }

    .padding-top-6 {
        padding-top: 64.5px;
    }

    .padding-bottom-1 {
        padding-bottom: 175px;
    }
}
