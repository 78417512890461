@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.createFirstTeam_cta {
    .layoutVertical;
    .widthLimitedLayout--md;
    .areaContainer;
    align-items: center;
    border-radius: 36px;
    padding: 40px;
}

.createFirstTeam_header_text {
    font-size: 28px;
    font-weight: 700;
}
