@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/text.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

job-target-profile {
    behavioral-ranges {
        margin-bottom: 10px;
    }
}

report-preview-action {
    background-color: @concrete;
}

.jobTargetProfile_wrapper {
    max-width: 800px;
    padding: 5px 30px;
    background: white;
    margin: 0 auto;
    font-size: 15px;
}

.jobTargetBenchmarkProfile_wrapper {
    max-width: 700px;
    padding: 5px 30px;
    background: white;
    margin: 0 auto;
    font-size: 15px;
}

.jobTargetProfile_sectionTitle,
.targetReferenceProfile_title,
.targetReferenceProfile_subtitle,
.jobTargetProfile_cognitiveSectionTitle {
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
}

.jobTargetProfile_reportTitle {
    font-weight: 300;
}

.jobTargetProfile_jobTitle {
    border-bottom: 4px solid;
}

.jobTargetProfile_sectionTitle,
.jobTargetProfile_cognitiveSectionTitle {
    text-transform: uppercase;
    border-bottom: 3px solid;
    padding-bottom: 3px;
    color: @job;
    font-weight: 500;
}

.jobTargetProfile_section {
    .flex-display();
    margin-bottom: 5px;

    target-reference-profile,
    target-traits,
    noteworthy-behaviors {
        .flex(1 1 0);
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.jobTargetProfile_piMastersTitle {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.jobTargetProfile_learnMore {
    text-align: center;
    margin-bottom: 10px;

    a {
        .lsg-link;
    }
}

.jobTargetProfile_copyright {
    text-align: right;
    font-size: 12px;
}

target-reference-profile {
    .flex-display();
}

@iconSize: 70px;

.targetReferenceProfile_icon {
    //.flex(1 0 @iconSize);  // flex was causing the icon to be stretched badly
    width: @iconSize;
    height: @iconSize;
    margin-right: 10px;
    margin-top: 30px;
    max-width: 100%; // fix the squishing issue that sometimes happens on the job report
}

.targetReferenceProfile_body {
    .flex-auto();
}

.targetReferenceProfile_title {
    text-decoration: underline;
    font-weight: normal;
}

.targetReferenceProfile_subtitle {
    background-color: @mercury;
    padding: 8px 4px;
}

.cogTarget_text {
    .flex-auto();
}

.jobCharacteristics_list {
    list-style-type: disc;
    list-style-position: inside;
}
