@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.targetPage {
    .jobTargetBreadcrumb {
        color: @silver;
        font-size: 23px;
        font-weight: 500;
        margin-left: 10px;

        .active {
            color: @smoky;
        }

        .divider {
            margin: 0 10px;
        }
    }

    set-job-target-header {
        .flex-wrap(wrap);
        .flex-direction(column);

        > * {
            .flex-display();
            .flex-auto();
        }
    }

    .target-question {
        .justify-content(center);
        font-size: 21px;
        span {
            color: @job;
            // Not ideal but angular is ignoring spaces in the string when
            // rendering the innerHtml
            margin: 0 5px;
        }
    }

    .instructions {
        display: block;

        p {
            font-size: 13px;
        }
    }

    .targetPage_pageCountContainer {
        .flex-display();

        button {
            .flex(0 1 auto);
            margin: auto;
        }
    }

    .targetPage_pageCount {
        color: @puerto-rico;
        .flex(1 0 auto);
    }
}

.behavioralTargetPage_questionsContainer {
    .flex-flow(row wrap);

    .behavioralTargetPage_question {
        .flex(1 0 50%);
        .flex-display();
        margin-bottom: 10px;

        input {
            margin-right: 10px;
        }
        label {
            font-weight: normal;
            font-size: 13px;
        }

        label span {
            vertical-align: middle;
        }
    }
}

.cognitiveTarget_questionsContainer {
    .flex-flow(row wrap);

    .cognitiveTarget_questionRow {
        .flex(1 0 100%);
        .flex-display();
        margin-bottom: 10px;
        min-height: 85px;
        transition: background 350ms ease-in-out;
    }

    .cognitiveTarget_questionRow--highlighted {
        background: lighten(#fde7df, 2.5);

        .btn {
            font-weight: 600;
        }
    }

    .cognitiveTarget_headerRow {
        .flex(1 0 83%);
        .flex-display();
        margin-left: 17%;
        margin-top: 20px;
        margin-bottom: 10px;
        div {
            .flex(0 1 25%);
            font-size: 21px;
            font-weight: 500;
            text-align: center;
        }
    }

    .cognitiveTarget_rowTitle {
        .flex(0 1 17%);
        margin-right: 10px;
        border-bottom: 1px solid @silver;

        > *:first-child {
            color: @smoky;
            font-size: 17px;
            font-weight: 700;
        }
        > *:last-child {
            font-size: 13px;
        }
    }
    .cognitiveTarget_rowResponses {
        .flex(1 0 83%);
        .flex-display();

        button {
            .flex(0 1 25%);
            background: 0;
            border: 1px solid @silver;
            border-right: 0;
            font-size: 12px;
            padding: 10px;
            white-space: inherit;
            .border-radius(0);

            &:first-child {
                .border-radiuses(0, 0, 4px, 4px);
            }

            &:last-child {
                .border-radiuses(4px, 4px, 0, 0);
                border-right: 1px solid @silver;
            }

            &.active {
                font-weight: 700;
                color: @tango;
                background-color: @cinderella;
                border: 2px solid @tango;
            }
        }
    }
}

.cognitiveTargetHelpModal {
    .flex-display();
    .flex-flow(row wrap);
    margin: -15px;

    .cognitiveTargetHelpModal_card {
        .flex(1 0 auto);
        .flex-basis(~'calc(50% - 30px)');
        margin: 15px;
        border: 2px solid @prestige;
        .border-radius(10px);
        padding: 10px;
        word-break: break-word;
    }
}
