@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors';

.cancelAndReturnText {
    color: @color-primary-400;
    font-weight: 500;
}

.boxWithGreyBorder {
    border: 3px solid @color-primary-100;
    border-radius: 2px;
    box-shadow: 0 0 4px 0 rgba(190, 187, 187, 0.88);
}

.greyCircleBorder {
    border-radius: 50%;
    height: 106px;
    width: 106px;
    border: 4px solid @color-neutral-100;
    margin: 47px 0 25px 0;

    .greyCircleBorder--iconInCircle {
        height: 37px;
        width: 79px;
    }
}

.progressOvalContainer {
    display: flex;
    flex-direction: row;
    margin: 70px 0 33px 0;

    .progressOval {
        border-radius: 50%;
        width: 20px;
        height: 20px;

        &.progressOval--doneColor {
            background-color: @spray;
        }

        &.progressOval--nextColor {
            background-color: @sail;
        }

        &.progressOval--moveRight {
            position: relative;
            right: -40px;
        }

        &.progressOval--moveLeft {
            position: relative;
            left: -40px;
        }
    }
}

.onboardingPageTitle {
    color: @color-neutral-900;
    font-size: 28px;
    font-weight: bold;
}
