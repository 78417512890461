.nextStepsGridLayout {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 400px 400px 400px;
    grid-template-columns: repeat(3, minmax(200px, 400px));
    -ms-grid-row-gap: 46px;
    grid-row-gap: 46px;
    grid-column-gap: 30px;
}

.contentContainer {
    position: relative;
    height: 230px;
}

.previewCardContent {
    display: block;
    max-width: 90%;
    max-height: 100%;
}

.purpleOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: @color-neutral-900;
}

.contentContainer:hover .purpleOverlay {
    opacity: 0.85;
}

.whiteMediumText {
    color: white;
    font-size: 20px;
}

.forceMiddle {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.childDivGreenBorder > div {
    border-color: @color-success;
    border-style: solid;
    border-width: 5px;
    margin: -3px; // allows for addition of border without changing object size
}

.forceTopLeft {
    position: absolute;
    top: 2%;
    left: 5%;
}

.contentFadeIn {
    opacity: 0;
    transition: 0.3s ease;
}

.contentContainer:hover .contentFadeIn {
    opacity: 1;
}

.gridSpanTitle {
    margin-top: 25px;
    -ms-grid-column: 1;
    -ms-grid-span: 3;
    grid-column: 1 / span 3;
}

.ieGridPlacement0 {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
}

.ieGridPlacement1 {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
}

.ieGridPlacement2 {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
}

.ieGridPlacement3 {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
}

.ieGridPlacement4 {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
}

.ieGridPlacement5 {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
}

.ieGridContainerSpacing {
    width: 1200px;
    margin: 0 auto;
}

.ieGridItemSpacing {
    margin: 22px 15px;
}
