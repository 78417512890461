@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

@flexItemMargin: 3%;

// REPORT-BUTTON-STYLE
.pi-report-config,
.pi-report {
    button {
        i {
            padding-right: 6px;
        }
    }
}

.pi-report {
    .box-sizing(content-box);
    border: 1px solid @concrete;
    .border-radius(5px);
    opacity: 1;
    .TransitionProperties(opacity 0.5s linear;);

    .report_actionButtons {
        background: @concrete;
        .flex-display();
        .justify-content(flex-start);
        .flex-auto();
        padding: 12px;

        button,
        a {
            margin-right: 5px;
            padding: 8px 12px;
        }
    }

    .report_actionButtons_left,
    .report_actionButtons_right {
        .flex-display();
        .flex-auto();
    }

    .report_actionButtons_right {
        .justify-content(flex-end);
    }

    .report_container {
        padding: 40px;

        .pdfIframe {
            min-height: 700px;
        }

        > * {
            margin-bottom: 10px;
        }
    }

    .report_description {
        margin: 20px 40px;

        span {
            font-weight: 500;
        }
    }
}

.pi-report-config {
    .language_dropDown {
        .flex-1();
        max-width: 476px;
    }

    .personInfo {
        margin-bottom: 0;
        padding: 6px 4px 6px 14px;
        max-width: 950px; // TODO: This is temporary while dates are missing

        &:nth-child(2n + 1) {
            background: @concrete;

            .pi-typeahead_inputWrapper--readonly input {
                background: @concrete;
            }
        }

        &:hover:not(.personInfo--header) {
            background-color: @panache;
        }

        .form_inputGroup.nameField {
            .flex(2 1 0);
            .flex-display();
            .flex-direction(column);
            .justify-content(center);
        }

        .form_inputGroup.dateField,
        .form_inputGroup.genderField,
        .form_inputGroup.purposeField {
            .flex(1 1 0);
        }
    }

    .emailOptions {
        justify-content: space-between;
        max-width: 900px;

        pi-checkbox {
            .flex-1();
        }
    }
}

report-preview-action-frame {
    width: 100%;
    margin-top: 30px;
}

.commonHeaderFooter {
    .flex-display();
    color: white;
    .justify-content(space-between);
    background-color: @jacarta;
    line-height: 31px;
}

.reportSkeleton {
    -webkit-print-color-adjust: exact;
    width: 900px;
    margin: 0 auto;
    color: @jacarta;
    background: white;

    .reportSkeleton_page {
        .flex-display();
        flex-flow: column;
        page-break-after: always;
        max-height: 1164px; //1164 ok

        &.reportSkeleton_lastPage {
            page-break-after: avoid;
            margin-bottom: -30px;
        }

        .reportSkeleton_page_content {
            flex: 1; //body of the page, fill in the height of the page
            overflow: hidden;
            height: 1060px;
        }

        .reportSkeleton_page_header {
            padding: 10px 25px;
            font-size: 16px;
            .commonHeaderFooter;

            abstract-icon {
                .align-self(center);
            }

            background-color: @jacarta;

            &.reportSkeleton_page_header--orgHeader {
                background-color: @color-person;
            }

            &.reportSkeleton_page_header--openEndedCommentsHeader {
                .bg-color-white;
            }
        }

        .reportSkeleton_page_footer {
            padding: 10px;
            font-size: 13px;
            .commonHeaderFooter;

            * .pi-logo {
                margin-left: auto;
                height: 32px;
            }

            b::before {
                counter-increment: pageCounter;
                content: counter(pageCounter);
            }

            &.reportSkeleton_page_footer--noBackground {
                color: black;
                background-color: white;
            }

            .pi-logo-red-with-name {
                width: 170px;
                height: 70px;
            }
        }
    }
}

// Newer versions of puppeteer started adding an additional
// and unwanted blank page at the end of the report
.lastPageOfReport {
    .reportSkeleton_page {
        page-break-after: unset;
    }
}

// the top left icon in the reports is imporperly positioned. This as done to overcome this
// but just in the person report page
.personDashboard
    .reportSkeleton
    .reportSkeleton_page
    .reportSkeleton_page_header
    *
    .pi-logo {
    margin-top: 0;
}

.dividingLine_red {
    border-bottom: 5px solid red;
    display: inline-block;
}

.pi-logo {
    margin-left: auto;
    height: 52px;
    align-self: center;

    &.pi-logo--small {
        height: 30px;
        margin-top: 0px;
    }

    &.pi-logo--medium {
        height: 40px;
    }
}
