@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/keyframes.less';

@smallSize: 10px;
@normalSize: 20px;
@largeSize: 40px;

.loading-dots-size(@inputSize) {
    li {
        height: @inputSize;
        width: @inputSize;
        border-width: (@inputSize / 4);

        &:nth-child(1n) {
            left: -3 * @inputSize;
        }
        &:nth-child(2n) {
            left: -1 * @inputSize;
        }
        &:nth-child(3n) {
            left: 1 * @inputSize;
        }
        &:nth-child(4n) {
            left: 3 * @inputSize;
        }
    }
}

.pi-loadingDots {
    list-style: none;
    margin: 10px 0;
    padding: 10px;
    position: relative;
    transform: translate(50%, -50%);
    font-size: 0;

    li {
        height: 0;
        position: absolute;
        top: 50%;
        left: 0;
        width: 0;
        margin: 0;
        border-radius: 100%;
        border-style: solid;
        border-color: @defaultLoadingColor;
        transform: transformZ(0);
        animation: LOADING 2s infinite;
        &:nth-child(1n) {
            animation-delay: 0s;
        }
        &:nth-child(2n) {
            animation-delay: 0.2s;
        }
        &:nth-child(3n) {
            animation-delay: 0.4s;
        }
        &:nth-child(4n) {
            animation-delay: 0.6s;
        }
    }

    .loading-dots-size(@normalSize);

    &.pi-loadingDots--large {
        .loading-dots-size(@largeSize);
    }

    &.pi-loadingDots--small {
        .loading-dots-size(@smallSize);
    }

    &.pi-loadingDots--person {
        li {
            border-color: @person;
            animation: LOADING-PERSON 2s infinite;
        }
    }
    &.pi-loadingDots--job {
        li {
            border-color: @job;
            animation: LOADING-JOB 2s infinite;
        }
    }
    &.pi-loadingDots--green {
        li {
            border-color: @successGreen;
        }
    }
    &.pi-loadingDots--red {
        li {
            border-color: @failureRed;
        }
    }
}

.pi-loadingDots--person .pi-loadingDots {
    li {
        border-color: @person;
        animation: LOADING-PERSON 2s infinite;
    }
}
.pi-loadingDots--job .pi-loadingDots {
    li {
        border-color: @job;
        animation: LOADING-JOB 2s infinite;
    }
}
.pi-loadingDots--green .pi-loadingDots {
    li {
        border-color: @successGreen;
    }
}
.pi-loadingDots--red .pi-loadingDots {
    li {
        border-color: @failureRed;
    }
}
