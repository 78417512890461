.soapbarButtonContainer {
    .flex-display();
    padding: 20px 0;
    .flex-direction(row);
    .flex-wrap(nowrap);
    .align-items(flex-start);

    .soapbarButtonContainer_spacer {
        .flex-auto();
        .flex-basis(0px);
    }
    .soapbarButtonContainer_buttonSpot {
        .flex-auto();
        .flex-basis(150px);
        button {
            display: block;
            margin: 0 auto;
        }
    }

    .soapbarButtonContainer_existingAssessmentsSummary {
        width: 150px;
        max-height: 60px;
        margin: 8px auto;
        text-align: center;
        font-style: italic;
        overflow-y: auto;
    }
    .soapbarButtonContainer_middle {
        .flex-auto();
        .flex-basis(50px);
        .align-self(center);
        text-align: center;
        font-family: 'Monserrat', sans-serif;
        font-size: 28px;
    }
}

@media (max-width: 767px) {
    .soapbarButtonContainer {
        .flex-direction(column);

        .soapbarButtonContainer_buttonSpot {
            .align-self(center);
        }
    }
}

.soapbar {
    cursor: pointer;
    max-width: 195px;
    white-space: initial;
    min-width: 175px;
    min-height: 95px;
    padding: 16px 10px;
    .border-radius(8px);
    border: 2px solid;
    font-size: 14px;
    background: white;

    i {
        margin: 5px auto 0;
        font-size: 18px;
        display: block;
        color: @silver;
    }

    svg {
        margin: 0 auto;
    }
}

.soapbar--parentChecked .soapbar i {
    color: white;
}

.soapbar.blue {
    border-color: @pacific;
    background: white;
    color: @pacific;
    svg {
        color: @pacific;
        fill: @pacific;
    }
}

.soapbar--parentChecked > .soapbar.blue,
.soapbar.blue:hover {
    background: @pacific;
    color: white;
    svg {
        color: white;
        fill: white;
    }
}

.soapbar.orange {
    border-color: @tango;
    background: white;
    color: @tango;
    svg {
        color: @tango;
        fill: @tango;
    }
}

.soapbar--parentChecked > .soapbar.orange,
.soapbar.orange:hover {
    background: @tango;
    color: white;
    svg {
        color: white;
        fill: white;
    }
}

.soapbar.purple {
    border-color: @smoky;
    background: white;
    color: @smoky;
    svg {
        color: @smoky;
        fill: @smoky;
    }
}

.soapbar--parentChecked > .soapbar.purple,
.soapbar.purple:hover {
    background: @smoky;
    color: white;
    svg {
        color: white;
        fill: white;
    }
}
