@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/constants';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors';
@import '../../../../../node_modules/font-awesome/less/variables';
.pi-folder {
    &.pi-folder--flatList {
        .pi-folder-icon {
            opacity: 1 !important;
            color: @smoky;
        }
        .k-content {
            border-bottom: 0;
        }
    }
    &.pi-folder--editable {
        .k-in {
            padding-left: 9px;
            line-height: 34px;
        }
    }
}

.k-widget {
    background-color: white;
    border-color: @silver;
    color: @port-gore;
    .box-shadow(none);
    border-style: solid;
    border-width: 1px;
    -webkit-appearance: none;
    line-height: normal;
    outline: 0;
    .box-sizing(content-box);
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-widget,
.k-widget * {
    -moz-background-clip: border-box;
    -webkit-background-clip: border-box;
    background-clip: border-box;
}

.k-treeview {
    .box-shadow(none);
}

.k-treeview .k-in {
    .border-radius(4px);
    border-color: transparent;
}

.k-treeview .k-icon {
    background-color: transparent;
    .border-radius(4px);
}

div.k-treeview {
    border-width: 0;
    background: 0;
    // overflow: auto; - removing this because it was messing up tooltips
    white-space: nowrap;
}

.k-treeview .k-item {
    display: block;
    background-color: transparent;
    border-width: 0;
    margin: 0;
    padding: 0 0 0 16px;
    &.folderTree-row {
        .flex-display();
    }
}

.k-treeview > .k-group,
.k-treeview .k-item > .k-group,
.k-treeview .k-content {
    margin: 0;
    padding: 0;
    background: 0;
    list-style-type: none;
    position: relative;
}

.k-treeview .k-checkbox {
    margin-top: 0.2em;
}

.k-treeview .k-icon,
.k-treeview .k-in {
    display: inline-block;
    vertical-align: middle;
}

.k-treeview .k-request-retry {
    vertical-align: baseline;
}

.k-treeview .k-plus,
.k-treeview .k-minus,
.k-treeview .k-plus-disabled,
.k-treeview .k-minus-disabled {
    margin-top: 0.25em;
    margin-left: -16px;
}

.k-treeview .k-sprite,
.k-treeview .k-image {
    margin-right: 3px;
}

.k-treeview .k-in {
    margin: 0px 0 0px 0.16666em;
    padding: 3px 0.3333em 3px 0.25em;
    line-height: 1.3333em;
    text-decoration: none;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
}

.k-treeview .k-drop-hint {
    position: absolute;
    z-index: 10000;
    visibility: hidden;
    width: 80px;
    height: 5px;
    margin-top: -3px;
    background-color: transparent;
    background-repeat: no-repeat;
}

.k-treeview {
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
}

.k-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.k-group {
    border-color: @silver;
    background-color: @concrete;
    li {
        padding-left: 24px;
    }
}

.pi-folder-icon {
    color: @prestige;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    .flex-display();
    &:hover {
        opacity: 1;
    }
    i {
        margin: auto;
    }
}

.k-icon {
    background-color: transparent;
    display: inline-block;
    width: 16px;
    height: 16px;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    text-align: center;
    -ms-high-contrast-adjust: none;
    .border-radius(4px);
    border-color: transparent;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
}

.k-plus {
    background-position: 0 -192px;
    margin-top: 0.25em;
    margin-left: -16px;
    cursor: pointer;
}

.k-minus {
    background-position: 0 -224px;
    margin-top: 0.25em;
    margin-left: -16px;
    cursor: pointer;
}

.k-in:hover {
    color: white;
    background-color: @color-primary-350;
    border-color: @color-primary-350;
}

.k-state-selected {
    color: white;
    background-color: @color-primary;
}

/* TODO Sytyleguide - layout */
@twoPanelPageSectionMargin: 20px;

.folderSelectPage {
    .flex-display();
    .flex-auto();
    .flex-wrap(wrap);
    margin: 0 -@twoPanelPageSectionMargin;
    .justify-content(space-between);
    font-size: 15px;
    > * {
        .flex(1 0 ~'calc(50% - @{twoPanelPageSectionMargin}* 2)');
        margin: 0 @twoPanelPageSectionMargin;
        margin-bottom: @footer-height;
    }
    .folderSelectPage_title {
        font-size: 18px;
        font-weight: 300;
        .flex-none();
        width: 100%;
        margin-bottom: 10px;
    }
    dropdown {
        margin-bottom: 16px;
        .clear-icon {
            display: none;
        }
    }
    pi-typeahead {
        margin-bottom: 16px;
    }
    .pi-sectionHeader {
        margin-top: 0;
        .flex-display();
        > span {
            flex: auto;
            margin: auto;
            margin-bottom: 0;
        }
    }
    .k-content {
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        .flex-display();
    }
    .k-item {
        padding-left: 48px;
    } // Hack to remove the first top indent. We might want to solve this by modifying
    // the .k-* styles
    .k-widget > .k-group {
        margin-left: -24px;
    }
    .k-in {
        margin: 0;
        padding: 0;
        border: 0;
        .purple();
    }
    .k-in:hover {
        background: none;
    }
}

.pi-folder {
    // Default folder icons
    .pi-folder-icon i:before {
        .font-awesome-icon(@fa-var-folder);
    }
    &:not(.pi-folder--flatList) {
        .pi-folder-icon--open i:before {
            .font-awesome-icon(@fa-var-folder-open);
        }
        .pi-folder-icon.pi-folder-icon--open {
            color: @dust;
        }
    } // Optional caret icons
    &.pi-folder--caretIcons {
        .pi-folder-icon {
            vertical-align: middle;
            display: inline-block;
            i:before {
                .font-awesome-icon(@fa-var-caret-right, 20px);
            }
            &.pi-folder-icon--open i:before {
                .font-awesome-icon(@fa-var-caret-down, 20px);
            }
        }
    } // Group icons
    &.pi-folder--groups {
        .pi-folder-icon {
            i:before,
            &.pi-folder-icon--open i:before {
                .font-awesome-icon(@fa-var-users);
            }
        }
    } // Org Structure icons
    &.pi-folder--orgStructure {
        font-size: 14px;
        font-weight: 500;
        .pi-folder-icon {
            color: @kingfisher;
        }
        .pi-folder-icon.pi-folder-icon--open {
            color: @color-primary-350;
        }
    }
    &.pi-folder--adminStyle {
        .bg-color-neutral-50;
        padding: 30px;
        margin: 20px 0px;
        font-size: 14px;
        border-radius: 6px;
        .pi-folder-icon {
            color: @color-primary;
        }
        .pi-folder-icon.pi-folder-icon--open {
            color: @color-primary;
        }
    }
    .pi-folder_delete {
        cursor: pointer;
        margin: auto;
        padding: 0 10px;
        color: @boulder;
        &:hover {
            text-decoration: none;
            color: @silver;
        }
    }
}

.pi-folder-icon {
    margin-right: 10px;
    color: @prestige;
    &:not(.pi-folder-icon--hasSubfolders) {
        opacity: 0;
    }
}

.folderTree-row {
    .flex-display();
}
