.lightGreyShadow {
    border: 2px solid @color-neutral-100;
    box-shadow: 0 0 3px @color-neutral-50;
}

.purpleLine {
    box-sizing: border-box;
    height: 5px;
    width: 100%;
    background-color: @color-primary-100;
}

.verticalAlign_midSizeContainer {
    height: 52px;
    display: flex;
    align-items: center;
}
