@import 'engage-employee-experience.less';

@engageHeader: #f1fafe;
/*Engage CSS */

.managerReport {
    -webkit-print-color-adjust: exact;
    font-size: 11.5pt;
    width: 900px;
    margin: 0 auto;
    counter-reset: pageCounter;
    color: @jacarta;

    h5 {
        margin: 0 0 5px 0;
        font-size: 20px;
    }

    * .dim_score {
        .scoreMarker {
            fill: @pacific;
        }

        .benchmarkMarker {
            fill: @kingfisher;
        }
    }

    .successImpact {
        background-color: @cruise;

        .ico-moon {
            color: @puerto-rico;
        }
    }

    .opportunityImpact {
        background-color: @sazerac;

        .ico-moon {
            color: @supernova;
        }
    }

    .managerReport_welcome {
        background-color: @jacarta;
        height: 175px;
        color: white;
        padding-top: 10px;
        text-align: center;
        line-height: 20px;

        .ico-moon {
            font-size: 22px;
        }

        p {
            font-size: 13pt;
            padding-top: 10px;
            margin: auto;
            width: 70%;
        }

        &.managerReport_welcome--orgReport {
            background-color: @color-person;
        }
    }

    .managerReport_about {
        background-color: @faded-purple;

        .managerReport_dimInfo {
            margin-bottom: 20px;
            .flex-display();

            .ico-moon {
                font-size: 20px;
                color: @nobel;
                margin: 0 5px 0 0;
            }

            > div {
                margin-right: 40px;
            }
        }

        &.managerReport_about--orgReport {
            background-color: @color-person-200;
        }
    }

    .managerReport_reminders {
        span {
            float: left;
            font-size: 80px;
            color: @onahau;
            margin: -20px 5px 0 0;
        }

        p {
            margin-right: 20px;
        }

        .ico-moon {
            color: @prestige;
        }

        .managerReport_impact_quick {
            margin: 10px 0;
        }
    }

    .managerReport_contents {
        background-color: @engageHeader;
        height: 360px;

        ul li {
            margin: 25px 0;
        }

        .managerReport_dim_score {
            margin-left: 110px;
        }

        .managerReport_legend {
            width: 240px;
            font-size: 12px;
            padding: 10px;
            color: white;
            .border-radius(5px);

            &.managerReport_legend_score {
                margin-left: 60px;
                background-color: @pacific;
            }

            &.managerReport_legend_benchmark {
                margin-left: 15px;
                background-color: @kingfisher;
            }
        }
    }

    .managerReport_overview {
        .managerReport_overview_dim_engagement {
            text-align: center;

            svg {
                display: inline;
                text-align: center;
            }
        }

        .managerReport_dim_overview {
            margin: 15px 0;
            .flex-display();
            .justify-content(space-between);

            > div {
                padding: 10px 15px;

                > div {
                    color: @prestige;
                    font-size: 18px;
                    padding-left: 6px;
                }

                &:nth-child(odd) {
                    background-color: @foam;
                    border-width: 0px 5px;
                }

                .noScore {
                    margin-top: 25px;
                }
            }
        }

        .managerReport_overview_impact {
            .managerReport_impactOppInfo {
                margin-left: auto;
                padding: 5px 10px;
                background-color: @faded-purple;
                color: @jacarta;
                .border-radius(5px);
            }

            ul {
                list-style: disc inside;
            }
        }
    }

    .managerReport_dim_content {
        .flex-display();
        .justify-content(space-between);

        &:nth-child(even) {
            background-color: #f6f5f5;
        }

        align-items: center;
        padding: 5px;
        color: @jacarta;

        .managerReport_dim_content-circle {
            .border-radius(50%);
            float: left;
            margin: 0 20px;
            height: 26px;
            width: 26px;
            line-height: 26px;
            font-size: 10.5px;
            text-align: center;
            vertical-align: middle;
            color: white;
            background-color: @pacific;
            position: relative;
        }
    }

    .managerReport_dim_score {
        margin-left: auto;
        padding-left: 15px;

        .noScore {
            font-size: 12px;
            font-style: italic;
        }
    }

    .managerReport_svg_backFill {
        fill: @silver;
        stop-color: @silver;
    }

    .managerReport_svg_stroke {
        stroke: @dust;
    }

    .managerReport_svg_backFillGradient-1 {
        stop-color: #f4f5f6;
    }

    .managerReport_svg_backFillGradient-2 {
        stop-color: #f3f3f3;
    }

    .managerReport_svg_barFillGradient-1 {
        stop-color: @pacific;
    }

    .managerReport_svg_barFillGradient-2 {
        stop-color: @sky;
    }

    .managerReport_svg_barFillGradient-warning {
        stop-color: @color-warning;
    }

    .managerReport_svg_barFillGradient-success {
        stop-color: @color-success;
    }

    .managerReport_svg_benchmarkLine {
        stroke: @color-success;
        stroke-width: 3;
    }

    .managerReport_svg_infoBaloonBackground {
        fill: @color-neutral-50;
    }
}

.managerReport_section {
    .flex-display();
    padding: 20px 30px;

    .ico-moon {
        font-size: 20px;
        margin: 0 20px 0 0;
    }
}

.managerReport_impact_wrapper {
    .managerReport_opportunity {
        > div {
            margin: 5px 0;
        }
    }

    .managerReport_actionable,
    .managerReport_challenges {
        padding: 15px 30px;

        ul {
            list-style: none;
            margin-left: 30px;

            li {
                margin: 10px 0;
            }
        }
    }

    .managerReport_challenges {
        background-color: @sazerac;

        .managerReport_dim_content {
            background-color: @sazerac;
            padding-left: 0;
        }

        .managerReport_dim_content-circle {
            background-color: @supernova;
            margin-left: 0;
        }

        .managerReport_challenge {
            margin: 10px 0 0 50px;
        }
    }

    .managerReport_actionable {
        li .ico-moon {
            color: @sail;
            font-size: 12px;
            margin: 0 5px 0 -15px;
        }
    }

    .managerReport_dim_header,
    .managerReport_actionable {
        padding-top: 20px;
        padding-bottom: 20px;

        .ico-moon {
            color: @prestige;
        }
    }

    &.managerReport_impact_wrapper--interactive {
        width: 700px;
        border-radius: 2px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
        margin-top: 50px;
    }
}

.svg_fill-person {
    fill: @color-person;
}

.svg_fill_primary {
    fill: @color-primary;
}

.svg_backFill_silver {
    fill: @silver;
}

.reportPage {
    padding: 60px;
}

.reportPageTitle {
    .reportPageHeader;
    .boldText;
    .color-port-gore;
    padding-bottom: 30px;

    .reportPageTitle_ruler {
        border-bottom: 4px solid @color-person;
        padding-top: 5px;
    }
}

.teamReportActionPlan_tool_container {
    .favorableScoreBar;
    padding: 25px 0px;
    align-items: inherit;

    .teamReportActionPlan_tool_body {
        .color-port-gore;
        .standardText;
        padding: 0px 25px;
        > span {
            .subTitleText;
            margin-bottom: 7px;
            display: block;
        }

        > a {
            text-decoration: underline;
            font-weight: 500;
            .color-kingfisher;
        }
    }
}
