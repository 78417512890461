pi-open-invitation-link {
    .setJobTargetReminder {
        padding: 16px;
        margin-bottom: 10px;
        border-width: 1px;
        border-style: solid;
        border-color: @pacific;
        background-color: @foam;
        border-radius: 4px;
        max-width: 400px;
    }

    .setJobTargetReminder_text {
        margin-bottom: 20px;

        ul {
            .defaultULStyling();
        }
    }

    .setJobTargetReminder_button {
        text-align: center;
    }
}
