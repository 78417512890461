@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/okapi.less';

.engageOrgDisplay {
    .pi-dashboard {
        max-width: 850px;
    }

    .flex-display();

    .engageOrgDisplay_title_icon {
        height: 45px;
        color: @electric-violet;
        margin: 21px 0px 0px 45px;
    }

    .orgDisplay_content_header {
        font-size: 21px;
        font-weight: bold;
        margin-top: 30px;
    }

    .orgDisplay_content_format {
        font-size: 18px;
    }

    .orgDisplay_tree_container {
        background: @color-neutral-50;
        .flex-display();
        .flex-direction(column);
        margin-top: 30px;
        padding: 10px 0 10px 0;
    }

    .orgDisplay_tree_container_tree {
        border-radius: 4px;
        padding: 10px 30px 10px 40px;

        //This prevents the selected folder from being highlighted when
        //attempting to selected a person for sending the BA
        .k-state-selected {
            background: inherit;
            color: inherit;
        }
    }

    //Some specific requested color changes
    .checkbox,
    .fa {
        color: @color-primary;
    }

    .missingBAIcon {
        margin-left: 5px;
        color: @color-danger;
    }

    .pendingBAIcon {
        margin-left: 5px;
        color: @color-primary-200;
    }

    //Setting this at designer request until the styleguide is updated to allow making
    //these icons less aggressive
    .alert-aside {
        font-size: 8px;
    }

    //Adjust spacing so checkbox-less entrys don't have as much of a gap
    .pi-folder-icon {
        margin-right: 0px;
    }
}
