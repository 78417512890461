@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.referenceProfileInfo {
    color: @jacarta;

    .referenceProfileInfo_icon {
        margin-right: 9px;
    }

    .referenceProfileInfo_container {
        display: inline-block;
        line-height: 30px;

        .referenceProfileInfo_title {
            font-size: 22px;
            font-weight: bold;
        }

        .referenceProfileInfo_container_details {
            font-size: 16px;
        }
    }

    &.referenceProfileInfo-right {
        .referenceProfileInfo_icon {
            margin-right: 0;
            margin-left: 9px;
        }

        .referenceProfileInfo_container {
            text-align: right;
        }
    }
}
