.configuration-callout {
    display: flex;
    justify-content: flex-start;
}

.configuration-visual {
    flex: 0 0 100px;
    display: inline-grid;
    grid-template-columns: 2;
    grid-template-rows: 2;
}

.configuration-quad {
    background-color: @color-neutral;
    color: white;
    margin: 2px 2px;
    padding: 12px 17px;
    text-align: center;
    line-height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    @border-radius: 6px;
    &:nth-child(1) {
        border-top-left-radius: @border-radius;
    }
    &:nth-child(2) {
        border-top-right-radius: @border-radius;
    }
    &:nth-child(3) {
        border-bottom-right-radius: @border-radius;
    }
    &:nth-child(4) {
        border-bottom-left-radius: @border-radius;
    }
}

.configuration-description {
    flex: 1 0 100px;
    padding: 10px 20px 0;
}

.configuration-cta {
    flex: 0 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.configuration-team {
    .configuration-quad {
        background-color: @color-person-800;
    }
    .configuration-quad.disabled {
        background-color: rgba(@color-person-800, 0.5);
    }
}

.configuration-strategy {
    .configuration-quad {
        background-color: @color-primary-900;
    }
    .configuration-quad.disabled {
        background-color: rgba(@color-primary-900, 0.5);
    }
}
