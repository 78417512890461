@import '../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/variables.less';

.auth {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'main advert';
}
.auth__main {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    grid-area: main;
}
.auth__header {
    flex: 0 0 auto;
    padding: 40px 60px 0px;
    .pi-logo {
        height: 75px;
    }
}
.auth__content {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.auth__footer {
    flex: 0 0 auto;
    padding: 20px 60px 40px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    .auth__footer__text {
        text-align: right;
        span {
            margin-left: 15px;
        }
    }
}
.auth__controls {
    flex: 0 0 300px;
}
.auth-link {
    color: @color-primary;
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
        color: @color-primary-link-hover;
    }
}

.auth__advert {
    grid-area: advert;
    margin: 0;
    min-width: 55vh;
    display: flex;
    justify-content: right;

    img {
        height: 100vh;
    }
}

@media (max-width: 1100px) {
    .auth__advert {
        display: none;
    }
}
