.multiContributorSendPage {
    .pi-dashboard_content {
        padding-bottom: 110px;
        max-width: 1200px;
    }
    .multiContributorSend_learningTip {
        align-self: flex-end;
        position: absolute;
        // bottom: 0;
        top: 25px;
        right: 0;
        margin: 0 25px;
    }
}

.hasCognitiveBanner .multiContributorSend_learningTip {
    top: 53px;
}

.multiJAReview {
    margin-bottom: @footer-height;
    .multiJAReview_cognitiveHeadline {
        color: @job;
        font-size: 16px;
        span {
            font-weight: 500;
        }
    }

    .multiJAReview_noReferenceProfiles {
        height: 200px;
        text-align: center;
        .flex-display();
        .justify-content(center);
        .flex-direction(column);
    }

    .pi-card_front {
        overflow: hidden;
    }

    .pi-card_content {
        margin: 0 (@cardPadding / -2);

        > * {
            .flex-auto();
            margin: 10px (@cardPadding / 2);
        }

        h5 {
            text-align: center;
        }

        pi-table {
            margin: auto;
            max-width: 900px;
        }
    }

    .multiJAReview_referencePattern {
        .flex-display();
        old-icon img {
            width: 80px;
        }
    }

    table old-icon {
        .justify-content(initial);

        img {
            width: 40px;
            height: 40px;
        }
    }

    reference-profile-grid {
        img {
            width: 40px;
            height: 40px;
        }
    }
}

@patternEntryMargin: 10px;
.jobPatternGrid {
    position: relative;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;

    // .help-text('Click patterns to toggle them');

    &.jobPatternGrid--centered {
        justify-content: center;
        .jobPatternEntry {
            margin: @patternEntryMargin;
        }
    }

    &.jobPatternGrid--selectable {
        .jobPatternEntry {
            cursor: pointer;
            &:not(.active):hover {
                border-color: #333;
                background: #fafafa;
            }
            &.active {
                border-color: orangered;
                background: fade(orange, 40%);
                &:hover {
                    background: fade(orange, 60%);
                }
            }
        }
    }

    .jobPatternGridEntry {
        position: relative;
        flex: 0 0 200px;
        min-height: 180px;
        padding: 8px 16px;
        padding-bottom: 16px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin: @patternEntryMargin;
        font-weight: 400;
        .flex-direction(column);
        .flex-display();

        &.active {
            border-color: #ccc;
            background: fade(orange, 30%);
        }

        .jobPatternGridEntry_description {
            font-size: 12px;
            display: block;
            margin-top: 4px;
            text-align: left;
        }

        input[type='checkbox'] {
            position: absolute;
            top: 8px;
            left: 8px;
        }
    }
}

.referenceProfileMatchArrows {
    .flex-display();
    max-width: 640px;
    margin: auto;

    .referenceProfileMatchArrows_highest,
    .referenceProfileMatchArrows_lowest {
        .flex-auto();
    }

    .referenceProfileMatchArrows_highest {
        &:before {
            .font-awesome-icon(@fa-var-long-arrow-left);
        }
    }

    .referenceProfileMatchArrows_lowest {
        text-align: end;
        &:after {
            .font-awesome-icon(@fa-var-long-arrow-right);
        }
    }
}
