@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.createJob_headerStart {
    border-right: 3px solid @silver;
    padding-right: 15px;
    margin-right: 15px;
}

@dashboardSubtitleHeight: floor(
    @dashboard-subtitle-font-size * @default-line-height
);
.createJob_selectBenchmark-tooltipPrompt {
    position: absolute;
    // TODO: this should probably just somehow be included in the dash subtitle
    top: -(@dashboardSubtitleHeight + @dashboard-title-margin-bottom);
    line-height: @dashboardSubtitleHeight;
    right: 0;
    color: @color-primary-350;
    font-size: 13px;
    cursor: default;

    &:hover,
    &:focus {
        color: @cold-purple;
    }
}

.createJob_confirmationBody {
    .flex-display(flex);
    .flex-direction(row);
    .justify-content(center);
    margin-top: 40px;

    .createJob_confirmationBody_prompts {
        text-transform: unset;
        width: 200px;
        height: 95px;
        padding: 16px;
        font-size: 15px;
        text-align: center;
        .border-radius(8px);

        &.createJob_confirmationBody_prompts--emphasis {
            border-width: 4px;
        }

        .fa {
            font-size: 36px;
            margin-bottom: 8px;
        }
    }
}
