@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors';
.managerScoreCard {
    .pi-dashboard_subtitle {
        margin: 15px 0 20px;
    }

    .managerScoreCard_personSearchTypeahead {
        max-width: 500px;
        input.OVR {
            text-align: left;
        }
    }
}
