@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../Features/engage-manager-report.less';
@import '../Features/person-dashboard.less';

.placardImageApp {
    font-family: 'Roboto';
    width: 800px;
    margin: 0 auto;
    padding: 50px;
    .color-approach {
        color: @pacific;
    }
    .placardTitle {
        font-weight: bold;
        text-align: center;
        border-bottom: solid 2pt @pacific;
        padding-bottom: 10px;
    }

    .referenceProfileInfo {
        padding: 25px 0px 25px 0px;
        .reference-profile {
            width: 140px;
        }
        .flex-display();
        .referenceProfileInfoDescription {
            .flex-direction(column);
            padding: 18px;
        }
    }

    .approachStyleList {
        font-size: 44px;
        font-weight: bold;
        .item {
            padding-bottom: 44px;
            &.highlight {
                font-size: 64px;
            }
        }
    }
    .patternBox {
        .flex-display();
        justify-content: center;
        position: relative;
        border-top: 1px solid @color-primary-350;
        .patternBox_title {
            position: absolute;
            margin-top: -9px;
            background: white;
            padding: 0px 10px;
        }
        .patternBox_pattern {
            width: 800px;
            padding: 12px 2px;
        }
    }

    .approachStyleList:nth-last-child(2) {
        .item {
            padding-bottom: 22px;
        }
    }

    .footerContainer {
        position: absolute;
        bottom: 20px;
        width: 800px;
        .footerContainer_footer {
            font-size: 15px;
            text-align: right;
            .flex-display();
            .justify-content(space-between);
            .pi-logo {
                height: 50px;
                margin-right: 8px;
            }
            .referenceLink {
                color: @pacific;
            }
        }
    }
}
