@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/guide.less';

.assessmentCenterDashboard {
    .tabNavigationList--assessmentCenter {
        .tab.activeTab {
            background: white;
        }
    }

    .assessmentCenterDashboard_content {
        .flex-display();
        .flex-direction(column);
        margin: 0 -25px;
        padding: 25px;

        .assessmentCenterDashboard_content_title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .assessmentCenterDashboard_content_editAssessmentDetails {
            padding-top: 25px;

            .editAssessmentDetails_header {
                font-size: 14px;
                padding: 4px 0px;
                color: @puerto-rico;
                margin-top: 15px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .assessmentCenterDashboard_content_openInvitationEditor {
            .pi-notification {
                margin-top: 3px;
                margin-bottom: 0;
            }

            .assessmentCenterDashboard_content_openInvitationEditor_sectionToggle {
                color: @color-primary-350;
                font-size: 14px;
                font-weight: normal;
                cursor: pointer;
                margin-left: 8px;
            }

            .assessmentCenterDashboard_content_openInvitationEditor_sectionSubitle {
                font-size: 16px;
            }

            .checkboxLabel--text {
                font-weight: normal;
            }
        }

        .assessmentCenterDashboard_content_checklist {
            .flex-display();
            .flex-direction(column);
            > * {
                padding: 10px;
            }
        }

        .assessmentCenterDashboard_content_splitBody {
            .flex-display();
            .flex-direction(row);

            > * {
                width: 50%;

                &:first-child {
                    padding: 0 10px 0 0;
                }
                &:last-child {
                    padding: 0 0 0 10px;
                }
            }

            .assessmentCenterDashboard_content_splitBody_emailPreview--frame {
                max-height: 400px;
                overflow-y: auto;

                .assessmentCenterDashboard_content_splitBody_emailPreview--body {
                    height: 20px;
                }
            }

            .email-header {
                font-weight: bold;
            }
        }
    }

    &.assessmentCenterDashboard_manageAssessments {
        .assessmentCenterDashboard_manageAssessments_controls {
            position: relative;
            margin-bottom: 15px;
            max-width: 600px;

            input {
                padding-left: 10px;
                margin-bottom: 15px;
                max-width: 600px;
            }
            .fa {
                position: absolute;
                left: 4px;
            }

            .btn {
                padding: 0 13px;
                line-height: 34px;
                height: 34px;
            }
        }
    }
}

.assessmentCenterDashboard_content_openInvitationEditor_sectionTitle {
    font-weight: bold;
    font-size: 16px;
}

.myOpenInvitationLinkPage {
    .assessmentCenterDashboard_content_openInvitationEditor {
        .assessmentCenterDashboard_content_openInvitationEditor_sectionSubitle {
            font-size: 16px;
        }

        .checkboxLabel--text {
            font-weight: normal;
        }
    }
}

.assessmentCenterDashboard_content_openInvitationEditor_sectionToggle {
    color: @color-primary-350;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    margin-left: 8px;
}

.tabCard {
    width: 200px;
    height: 140px;
    border: 1px solid @color-neutral-50;
    border-radius: 2px;
    box-shadow: 0 0 4px 0 @color-neutral-300;
    margin: 10px 35px 10px 0px;
    &:hover {
        background-color: @kingfisher;
        color: white;
    }
}

.tabCard--active {
    background-color: @kingfisher;
    color: white;
}
