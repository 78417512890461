@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.engageNoOrgDisplay {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    padding-top: 30px;

    .engageNoOrgDisplay-uploadIcon {
        font-size: 34px;
        color: @kingfisher;
    }
}

.engageNoOrgWarningIco {
    height: 40px;
    fill: @carnation;
    display: block;
    margin: auto;
}

.engage-survey--checklistSvg {
    width: 84px;
    margin: auto;
}

.runSurvey_orgDisplay_tree {
    background: @concrete;
    padding: 20px 0px 20px 15px;
    border-radius: 4px;
    .k-in {
        pointer-events: none;
    }
}

.runSurvey_hideButton {
    visibility: hidden;
}

.engage-survey--subHeader {
    line-height: 25px;
    font-weight: 400;
    padding-bottom: 20px;
}

.engageSurvey-status--container {
    background-color: white;
    .box-shadow(0 2px 11px 0 rgba(0, 0, 0, 0.11));
    .border-radius(6px);
    overflow: hidden;
    padding-top: 40px;
    min-width: 815px;
}

.engageSurvey-status--expandHeader {
    background-color: @color-person;
    color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.engageSurvey-status--details {
    font-size: 15px;
    padding: 20px 0px 30px 25px;

    h3 {
        color: @color-primary-900;
    }

    .engageSurvey-status--info {
        padding-bottom: 20px;
        .flex-display();
        .flex-direction(row);

        > div {
            .flex-1();
            margin-right: 25px;
        }

        div.survey--recipients {
            border-radius: 4px;
            background-color: @concrete;
            margin: auto;
            text-align: center;
            padding: 10px;

            h2 {
                color: @color-primary;
                font-weight: bold;
                margin: 10px 0;
            }
        }

        div.engageSurvey-status--importantDates {
            > div {
                border-radius: 4px;
                background-color: @concrete;
                padding: 10px;
                margin: 0 0 11px 0;
            }
        }
    }

    .engageSurvey-status-emails {
        height: 280px; // Static height set to fix rendering issue in ie11.  More involved issue may exist here with usage of flexbox and IE.
        .flex-display();
        .flex-direction(column);

        > div {
            flex: 1;
        }

        div.engageSurvey-status--timeDisplay {
            margin-left: 15px;
            min-width: 130px;
            justify-content: center;
        }
    }

    .engageSurvey-status--dateAndTime {
        .flex-display();
        .flex-direction();

        .engageSurvey-status--dateDisplay {
            min-width: 350px;
        }
    }

    .engageSurvey-status--dateDisplay,
    .engageSurvey-status--timeDisplay {
        border-radius: 4px;
        background-color: @concrete;
        padding: 8px 14px;
        margin: 0 0 8px 0;
        .flex-display();
        .flex-direction();

        :first-child {
            color: @color-primary-400;
            font-weight: 700;
            min-width: 100px;
        }
    }
}

.engage-survey--uploadReminderContainer {
    margin: 14px 0px;
    padding: 15px;
    border-radius: 6px;
    line-height: 23px;

    .engage-survey--uploadWarningContainer_icon {
        font-size: 30px;
        padding: 5px 15px 15px 15px;
        color: @error-dark;
    }

    .engage-survey--uploadReminderContainer_subtitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }
}

.engage-survey--infoIcon_icon {
    padding: 3px 15px 15px 5px;
    color: @kingfisher;
}

.engage-survey-org_summary--offsetCounts {
    margin-left: 18px;
}

.engage-survey--confirmContainer {
    .flex-display();
    flex-wrap: wrap;

    .engage-survey--confirmChild {
        flex: 1 0 45%;

        .engage-survey--subTitle {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 10px;
        }

        .engage-survey--employeeCnt {
            text-align: center;
            font-size: 14px;
            height: 84px;
            padding-top: 21px;
            background-color: @concrete;
            border-radius: 4px;
            margin-right: 26px;

            div {
                font-size: 32px;
                font-weight: bold;
                line-height: 28px;
                color: @color-primary;
            }
        }

        .engage-survey--surveyRecipients {
            text-align: center;
            font-size: 14px;
            height: 84px;
            padding: 10px 0px 20px;
            background-color: @concrete;
            border-radius: 4px;
            margin-right: 26px;
        }

        .engage-survey--dateConfirmation {
            height: 84px;

            div {
                padding: 10px 10px 10px 15px;
                background-color: @concrete;
                border-radius: 4px;
                margin-bottom: 7px;

                span {
                    color: @color-primary;
                    font-weight: bold;
                }
            }
        }

        .engage-survey--updateMessage {
            color: @color-primary;
            font-size: 12px;
            line-height: 21px;
            padding-top: 12px;
            padding-bottom: 10px;

            a {
                color: @color-primary;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.engage-survey--orgcounts {
    .orgCountCardsMargin:first-of-type {
        margin-left: 0px;
    }
    .orgCountCardsMargin:last-of-type {
        margin-right: 0px;
    }
}

.engage-survey--smalltitle {
    .largeCardTitle {
        font-size: 18px;
        font-weight: 450;
    }
}

.engage-survey--stepHeader {
    color: @prestige;
    font-size: 26px;
    padding-bottom: 20px;
}

.engage-survey--confirmReminder {
    margin: 14px 0px;
    padding: 15px;
    border-radius: 8px;
}

.cancelSurvey-modal {
    .modal-dialog {
        max-width: 600px;
    }
}

.engage-survey--emailContainer {
    padding: 30px;
    margin-bottom: 100px;
    overflow-x: visible;
    overflow-y: visible;
}

.engage-survey--resendDescription {
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 30px;
}

.engageEmail-modal {
    .pi-modalContent {
        margin: 48px 144px;
    }

    .pi-modalContent .pi-modalBody {
        margin: 24px 86px;
    }
}

.engageEmail-modal--fullName {
    margin: 54px auto 0;
    font-size: 30px;
    font-weight: 500;
    color: @color-neutral-900;
}

.engageEmail-modal--description {
    display: block;
    margin-bottom: 6px;
}

.engageEmail-modal--updateNote {
    display: block;
    font-style: italic;
}

.engageEmail-modal--label {
    display: block;
    margin: 6px 0px;
    font-weight: 500;
    color: @color-neutral-900;
}

.engageEmail-modal--buttons {
    .flex-display();
    .justify-content(space-between);
    margin-bottom: 36px;
}

.engageEmailInvitePreview {
    // The Preview email for the Diagnose Invite needs a modal with different padding and size
    .modal-dialog {
        max-width: 540px;
        .assessmentCenterDashboard_content_splitBody_emailPreview--body {
            // The style in consideration is an inline style on a div in the Generic component and hence the important
            padding: 0 38px !important;
        }
    }
}
