@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.relationshipGuideDashboard_people {
    position: relative;
    display: block;
    margin-top: 20px;
    padding-top: 60px;
    min-height: 170px;
}

.relationshipGuideDashboard_people_atom {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -77px;
    margin-left: -83px;
    width: 125px;
    height: 125px;
    background: @jacarta;
    border-radius: 125px;
    border: 5px solid @onahau;
    padding: 15px;
}

.relationshipGuideDashboard_people_dropdowns {
    .flex-display();
    .justify-content(space-evenly);
    align-items: center;

    .ok-pill {
      margin: 0;
    }

    person-typeahead input {
        background: white;
    }
}
.relationshipGuideDashboard_people_editCTA {
    position: absolute;
    top: -50px;
    right: 12px;
    font-size: 20px;
    color: @jacarta;
    cursor: pointer;
}
.relationshipGuideDashboard_people_buttons {
    position: relative;
    text-align: center;
    margin-top: 60px;

    .btn {
        min-width: 150px;
    }
}

.relationshipGuideDashboard_people_generatedPerson {
    position: relative;
    margin-top: 30px;
    margin-left: 2px;
    .relationshipGuideDashboard_people_generatedPerson_leftProfile {
        margin-left: 15px;
    }
    .relationshipGuideDashboard_people_generatedPerson_rightProfile {
        position: absolute;
        top: 0;
        right: 22px;
        margin-left: 22px;
    }
}

.relationshipGuideDashboard_sendReportBar {
    margin-left: 170px;
}


.referenceProfile {
    max-height: 24px;
}

.relationshipGuideDashboard_content_promptAttribute {
    .relationshipGuideDashboard_content_promptAttribute_title {
        .flex-display();
        margin-top: 50px;
        font-size: 22px;
        line-height: 60px;

        .relationshipGuideDashboard_content_promptAttribute_title_icon {
            .flex-display();
            .flex-direction(column);
            .justify-content(center);
            margin-right: 10px;
            width: 60px;
            height: 60px;
            border-radius: 60px;
            background: @concrete;
            text-align: center;

            &.relationshipGuideDashboard_content_promptAttribute_title_icon-strengths {
                color: @puerto-rico;
            }
            &.relationshipGuideDashboard_content_promptAttribute_title_icon-cautions {
                color: @supernova;
            }
            &.relationshipGuideDashboard_content_promptAttribute_title_icon-tips {
                color: @pacific;
            }
        }
    }

    .relationshipGuideDashboard_content_promptAttribute_textBody {
        margin: 10px 0 0 70px;
        font-size: 16px;
    }
}

.relationshipGuideDashboard_content {
    .flex-display();
    .flex-flow(column);
    padding: 15px 50px 40px;

    &.relationshipGuideDashboard_content-preGen {
        padding-top: 40px;
    }

    .relationshipGuideDashboard_content_promptTitle {
        margin-top: 20px;
        font-size: 18px;
    }

    .relationshipGuideDashboard_content_masterToggle {
        .flex-display();
        .justify-content(space-between);

        a,
        label {
            color: @jacarta;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .relationshipGuideDashboard_content_patternBoxContainer {
        .flex-display();
        .justify-content(space-between);
        position: relative;
        padding: 25px 0;

        .relationshipGuideDashboard_content_patternBoxContainer_title {
            position: absolute;
            margin-top: -9px;
            background: white;
            padding: 0px 10px;
        }
        .relationshipGuideDashboard_content_patternBoxContainer_patternBox {
            position: relative;
            width: 50%;
            padding-left: 40px;

            .relationshipGuideDashboard_content_patternBoxContainer_patternBox_info {
                .relationshipGuideDashboard_content_patternBoxContainer_patternBox_info_referenceIcon {
                    height: 24px;
                    margin-right: 2px;
                }
            }
        }
    }

    .relationshipGuideDashboard_content_strenghtsCautions {
        .relationshipGuideDashboard_content_strenghtsCautions_toggleLinks {
            width: 400px;
            margin: 15px auto;
        }
    }
}

.toggleLinks {
    position: relative;
    width: 100%;
    .flex-display();
    .justify-content(space-evenly);

    .toggleLinks_link {
        .flex-display();
        .flex-direction(column);
        .justify-content(center);
        background: @mercury;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        text-align: center;
        color: white;
        font-size: 28px;
        cursor: pointer;

        &.toggleLinks_link-strengths {
            background: linear-gradient(
                180deg,
                @turquoise 0%,
                @puerto-rico 100%
            );
        }
        &.toggleLinks_link-cautions {
            background: linear-gradient(
                180deg,
                @corvette 0%,
                @sandy-brown 100%
            );
        }
        &.toggleLinks_link-tips {
            background: linear-gradient(180deg, @spray 0%, @picton 100%);
        }
    }
}
