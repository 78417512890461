@import '../../../../../node_modules/@predictiveindex/client-core/styles/modal.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/buttons.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/text.less';

.onboarding-modal {
    .textbox-width {
        width: 350px;
        margin: auto;
    }

    .modal-dialog {
        max-width: 420px;
    }

    .go-button {
        height: 48px;
        font-size: 16px;
        font-weight: 500;
    }

    .vertical-align {
        display: inline-block;
        vertical-align: middle;
    }

    .move-right {
        display: flex;
        justify-content: flex-end;
    }

    .set-middle {
        display: flex;
        justify-content: center;
    }

    .large-text-jacarta {
        color: @jacarta;
        font-size: 28px;
        font-weight: 500;
        line-height: 37px;
        text-align: center;
    }

    .medium-text-presige {
        color: @prestige;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
    }

    .small-text-prestige {
        color: @prestige;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
    }

    .padding-top-medium {
        padding-top: 45px;
    }

    .padding-top-smaller {
        padding-top: 35px;
    }

    .padding-bottom-smaller {
        padding-bottom: 35px;
    }

    .padding-top-small {
        padding-top: 25px;
    }

    .padding-right-medium {
        padding-right: 38px;
    }

    .link {
        color: @kingfisher;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
    }
}
