@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.landingPage {
    .navigationOption {
        .navigationOption_button {
            width: 455px;
            height: 132px;
            text-transform: unset;
            color: @pacific;

            .navigationOption_button_icon {
                width: 65px;
                height: 50px;
            }
            .navigationOption_button_icon--thin {
                font-size: 40px;
            }

            .navigationOption_button_mainText {
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 10px;
            }
            .navigationOption_button_subText {
                font-size: 13px;
                color: black;
                white-space: normal;
            }
        }
    }
}
