.expandingInformation {
    width: 100%;

    .expandingInformation_buttonHeader {
        position: relative;
        padding: 0 20px;
        width: 100%;
        line-height: 48px;
        height: 48px;
        color: @color-primary-350;
        border-color: @color-primary-350;
        border-radius: 20px;
        cursor: pointer;
        font-size: 16px;
        .TransitionProperties(border-radius ease-out .1s;);

        &:hover,
        &:active,
        &:focus {
            background: @concrete;
        }

        &.expandingInformation_buttonHeader--expanded {
            background: @color-primary-350;
            color: white;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            &:hover,
            &:active,
            &:focus {
                background: @royal-purple;
            }

            .fa-angle-down {
                .TransformProperties(rotate(180deg) ;);
            }
        }

        .expandingInformation_buttonHeader_content {
            text-transform: none;
        }
        .fa-angle-down {
            position: absolute;
            right: 20px;
            top: 15px;
            font-size: 20px;
            font-weight: bold;
            right: 20px;
            .TransitionProperties(transform ease-out .35s;);
        }
    }

    .expandingInformation_body {
        overflow: auto;
        max-height: 0;
        border-radius: 0 0 20px 20px;
        box-shadow: 0 0 12px 0 @silver;
        .TransitionProperties(max-height ease-out .15s;);

        &.expanding_body--expanded {
            padding: 0 20px 20px;
            max-height: 500px;
            .TransitionProperties(max-height ease-out .35s;);
        }

        .expandingInformation_body_contentBlock {
            margin-top: 20px;
            font-size: 16px;

            a {
                color: @kingfisher;
            }
        }
    }
}
