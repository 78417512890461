@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

.legend.svg-placement {
    border: 1px solid #a0a0a0;
    border-radius: 3px;
    background: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 10pt;
    position: absolute;
}

.hide-drop-down {
    display: none;
}
.visible {
    display: block;
}

.legend > * {
    padding: 2px 10px;
}

.legend .title {
    border-bottom: 1px solid #a0a0a0;
}

.legend-entry {
    white-space: nowrap;
}

.legend-entry .swatch {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border: 1px solid @prestige;
}
.legend-entry.neutral .swatch {
    background-color: @situationally;
}

.legend-entry.slightly .swatch {
    background-color: @moderately;
}

.legend-entry.moderately .swatch {
    background-color: @very;
}

.legend-entry.extremely .swatch {
    background-color: @extremely;
}

.legend-summary {
    margin-bottom: 15px;
    font-weight: bold;
    &:after {
        content: ':';
    }
}

.legend-averagePattern {
    margin-top: 15px;
    > * {
        vertical-align: middle;
        display: inline-block;
    }
}

.purple-light.high-contrast.no-shadow {
    color: #8667b2;
    text-shadow: none;
}

.close.non-opacity {
    opacity: 1;
}
