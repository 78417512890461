@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/OkapiStyleGuide/variables.less';

.engageSurveyExperience_title {
    color: @port-gore;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding-bottom: 10px;
}

.engageSurveyExperience_body {
    color: @port-gore;
    font-size: 16px;
    line-height: 24px;
}

.engageEmpExperience_tab-container {
    padding: 72px 0 20px;
    min-height: calc(100vh);
}

.engageEmpExperience__TabControl {
    margin: 0 auto;

    ul.tabNavigationList:first-child {
        margin: 0;
    }

    .disableTab .tab {
        background-color: @color-neutral-100;
        color: @color-disabled-text;
        cursor: not-allowed;
    }
}

.survey-overallInfo {
    border-radius: 4px;
    background-color: @concrete;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    min-width: 170px;
    height: 84px;

    .survey-detail {
        color: @color-primary;
        font-weight: bold;
        font-size: 32px;
    }
}

/*Action Center Tab*/
.engageActionCenter {
    .engageActionCenter-expandParentHeader {
        background-color: @color-person-700;
        color: white;
    }

    .engageActionCenter-categories {
        width: 800px;
        margin-bottom: 10px;
    }

    .engageActionCenter-expandItem {
        .expansionPanel {
            background-color: @color-neutral-50;
            border-radius: 6px;
            margin-bottom: 11px;

            strong {
                display: inline;
                font-weight: inherit;
                color: @color-primary-200;
            }
        }
    }

    .engageActionCenter-checkItem {
        width: 30px;
        font-size: 20px;
        margin-left: 10px;
        padding-top: 10px;

        .ico-moon-checkmark-circle:hover {
            cursor: pointer;
            color: @color-success-100;
        }

        .engageActionCenter-noSurveyCheckItem {
            color: @color-neutral-300;
        }
    }

    .engageActionCenter-actions {
        margin-top: 15px;

        button {
            margin-right: 15px;
        }
    }

    .engageActionCenter-contentOnly {
        padding: 15px 0;
        color: @color-disabled-text;
    }
}

.engageActionCenter-supportInfo.supportInfoBoxParent {
    margin-left: 78px;

    .supportInfoBox {
        margin-left: 0px;
    }

    .cardTitle {
        &:last-of-type {
            margin-top: 55px;
        }
    }
}

.engageScheduleSurvey-supportInfo.supportInfoBoxParent {
    margin-left: 17px;
    min-height: 400px;

    .supportInfoBox {
        margin-left: 0px;
    }

    .cardTitle {
        &:last-of-type {
            margin-top: 20px;
        }
    }
}

.engageScheduleSurvey-supportInfo-stackedInfoBoxParent {
    .flex-display();
    .flex-flow(column);
    .align-items(left);

    .cardTitle {
        margin-top: 17px;
        padding: 0px 0px 13px 15px;
    }

    .supportInfoBox_container {
        padding: 0px 35px 20px 35px;
    }
}

.engage_insights_download-message {
    .standardText;
    .boldText;
    margin-top: 10px;
    color: @color-primary;
}

.favorableScoreBar {
    padding: 4px 0px;
    .bg-color-neutral-50;
    border-radius: 6px;
    margin-bottom: 8px;
    align-items: center;

    .impactLevel {
        padding-left: 20px;
        vertical-align: middle;
    }

    .rosettaStoneText {
        .smallContent;
        line-height: 20px;
        padding: 10px 16px;
    }

    .favorableScore {
        .layoutCenterAlign();
        .align-items(center);
        padding: 8px 8px;
        margin-right: 8px;
        color: white;
        .largeText;
        .bg-color-person;
        .boldText;
        height: 33px;
        width: 44px;
        border-radius: 6px;
    }
}

/*View Insights Tab*/
.engageViewInsights-score {
    width: 192px;
    height: 133px;
    text-align: center;
    margin-bottom: 15px;
    padding: 25px 25px 10px;
    border-radius: 6px;

    &:hover {
        cursor: pointer;
        background-color: @color-person-700;
    }
}

.engageViewInsights-diffContainer {
    width: 30px;
    text-align: center;
    border-radius: 4px;
    color: white;
    padding-top: 2px;
}

.engageHeatMap-legend {
    text-align: center;
    margin: 35px 0px 0px 44px;

    .engageHeatMap-legend-box {
        height: 22px;
        width: 91px;
        border-radius: 3px;
        margin: 0px 5px 8px 0px;
    }
}

.engageViewInsights-impactIcon {
    width: 30px;
    margin: auto;
}

.engageViewInsights-supportInfo.supportInfoBoxParent {
    margin-left: 18px;

    .supportInfoBox {
        margin-left: 0px;
    }

    .cardTitle {
        &:last-of-type {
            margin-top: 55px;
        }
    }
}

.engageViewInsights-keyContainer {
    width: 231px;
    height: 134px;
    padding: 11px 0px 11px 17px;
    .bg-color-neutral-50;
    border-radius: 6px;

    .favorableScore {
        .layoutCenterAlign();
        .align-items(center);
        padding: 8px 8px;
        margin-right: 8px;
        color: white;
        .largeText;
        .bg-color-person;
        .boldText;
        height: 33px;
        width: 44px;
        border-radius: 6px;
    }
}

/* Team Report 2.0 / Action Plan Intro Page */
.actionPlanIntro--header {
    background-color: @color-person;
}

.actionPlanIntro--headerTitle {
    font-size: 66px;
    color: #fff;
    font-weight: bold;
    padding: 110px 0px 0px 80px;
}

.actionPlanIntro--headerSubtitle {
    color: #fff;
    padding: 20px 0px 90px 85px;
    font-size: 20px;
}

.actionPlanIntro--body {
    padding: 60px 78px;
}

.actionPlanIntro--sectionHeader {
    padding: 25px 0px 15px;
}

.actionPlanIntro--actionItem {
    padding: 15px 25px;
    margin-bottom: 8px;
    background-color: @color-neutral-50;
    border-color: @color-neutral-50;
    font-size: 15px;
}

.teamReportIntro--headerTitle {
    font-size: 66px;
    line-height: 74px;
    color: #fff;
    font-weight: bold;
    padding-left: 80px;
}

.teamReportIntro--sectionHeader {
    .titleText;
    .boldText;
    padding: 20px 0px 15px;
}

.reminderTextHeader {
    b {
        color: @color-person;
    }
}

.teamReportYourResult--dimensionText {
    .standardText;
    span {
        color: @color-person;
        font-weight: 500;
    }
}

.teamReportActionPlan--actionText {
    .teamReportYourResult--dimensionText;
    padding: 0px 50px 20px 0px;
}

.teamReportActionPlan--challengesText {
    .extraSmallText;
    font-style: italic;
    padding: 15px 0px 40px;
}

.teamReportActionPlan--sectionHeader {
    .titleText;
    padding-bottom: 15px;
}

.teamReportActionPlan--notesLine {
    border-bottom: 1px solid @color-neutral-400;
    padding-top: 35px;
    width: 100%;
}

/* End Team Report/Action Plan Styling */
