@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';

@mobileFlexItemMargin: 5px;
@flexItemMargin:3%;

//
// Separate out things that need to change for mobile to desktop
//
.personDashboard {
    .personDashboard_content {
        width: 100%;
    }
    .personDashboard_routerWrapper > * {
        width: 100%;
    }
}

.desktop(
    {.personDashboard {.personDashboard_content {margin: 0 -@flexItemMargin;
        width: auto;} .personDashboard_routerWrapper > * {width: auto;}}}
);
//
//
//

.makeIconBig .dashboard_referenceInfo--icon {
    height: 45px;
}

.makeIconHuge .dashboard_referenceInfo--icon {
    height: 54px;
}

.personDashboard {
    background: white;

    .pi-dashboard_title {
        // To get sub text to appear underneath header
        margin-bottom: 0;
    }

    .personDashboard_routerWrapper,
    .personDashboard > loading-dots {
        background: @concrete;
        min-height: 100vh;
        margin: 0 -@dashboardGutter;
        padding: @dashboardGutter;
        border-top: 1px solid @silver;
    }

    .personDashboard_content {
        .flex-display();
        .flex-wrap(wrap);
        .flex-auto();
    }

    .personDashboard_routerWrapper {
        router-outlet {
            display: none;
        }
        > * {
            .flex-auto();
        }
    }

    .pi-dashboard_info {
        behavioral-icon,
        cognitive-icon {
            display: inline;
            svg {
                display: inline;
            }
        }
    }

    .dashboard_referenceInfo {
        padding: @dashboardGutter @dashboardGutter 20px;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: -20px;

        .dashboard_referenceInfo--icon {
            height: 24px;
            margin-right: 4px;
        }
    }

    .pi-dashboard_title {
        color: @person;
        font-size: 36px;
    }

    .personDashboard_navigationControls {
        .flex-display();
        .justify-content(space-between);
    }

    .personDashboard_navigationControls_adminDropdown {
        align-self: flex-end;
        height: 30px;
        font-weight: 500;
        max-width: 250px;
        .singleLineHeightIE10(35px);
        width: auto;
        .flex(0 1 auto);
    }

    .pi-card {
        margin-bottom: 0;
        height: auto;
        box-shadow: none;
        .pi-card_open {
            .pi-card_header {
                border-radius: @standardBorderRadius @standardBorderRadius 0 0;
            }
        }

        .pi-card_header {
            border: none;
            border-radius: @standardBorderRadius;
        }
    }

    .closableCard {
        .pi-card {
            border: 1px solid @color-neutral-100;
            border-radius: @standardBorderRadius;
        }

        .pi-card_header {
            background: @color-person;
            color: white;
        }
        .pi-card_header_title {
            padding: 12px 17px;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .behavioralCard,
    .cognitiveCard {
        margin-bottom: @flexItemMargin * 2;
    }

    .assessment-details-card {
        .pi-card_content {
            padding-top: 12px;
        }
    }
    .behavioralCard,
    .cognitiveCard {
        .pi-card .pi-card_header .pi-card_header_link:hover {
            background: @concrete;
            border-color: @person;
        }
        .pi-card_dropdownContent {
            min-width: 280px;
        }
        .pi-card_dropdownContent_item {
            .flex-display();
            cursor: pointer;
            > * {
                .flex-auto();
            }
            assessment-reminder,
            behavioral-thumbnail,
            cognitive-thumbnail {
                .flex(0 1 160px);
            }
        }
    }
    .watermark-content {
        text-align: center;
        .fa-clock-o {
            color: @torch;
        }
        .watermark-image {
            height: 94px;
            background-size: 80px 60px;
            padding-bottom: 14px;
        }
    }

    edit-person-details > form {
        .flex-auto();
    }

    ok-pillbox-with-typeahead {
        max-width: 500px;
    }
}

.behavioralReport {
    .behavioralReport_step {
        font-size: 18px;
        font-weight: 300;
        span {
            color: @puerto-rico;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}

.personReportIcon {
    img {
        height: 90px;
        width: 90px;
    }
}

@cardMargin: 12.5px;

.space1of4 {
    .flex-basis(~'calc(25% - 2 * 12.5px)');
    margin: 0 @cardMargin;
}
.space3of4 {
    .flex-basis(~'calc(75% - 2 * 12.5px)');
    margin: 0 @cardMargin;
}

.fullspace {
    .flex-basis(100%);
    margin: 0 @cardMargin;
}

.halfspace {
    .flex-basis(~'calc(50% - 2 * 12.5px)');
    margin: 0 @cardMargin;
}

.personDashboard_content {
    card {
        margin-bottom: 25px;
        height: ~'calc(100% - 25px)'; //;
    }
    card.closableCard {
        // This was put in to prevent closable cards from having a permanent
        // ghost card when closed
        height: auto;
    }
}

.reportSkeleton {
    .personDashboard_content {
        font-size: 11px;
    }
    .patternInsights_cardContainer_header {
        height: 35px;
        line-height: 25px;
    }
    .patternInsights_cardContainer_header_name {
        font-size: 16px;
    }
    .patternInsights_cardContainer_body_subheader {
        font-size: 16px;
    }
    .pi-card-content {
        min-height: 0px;
    }
    .personDashboard_content {
        margin: 0;
    }

    .pi-card .pi-card_content {
        // Make some space in report to get it on one page
        min-height: 0px;
    }
    .personDashboard_content {
        .behavioralCard {
            margin-bottom: 0;
        }
    }

    .patternInsights_card_behaviorTraits > div,
    .patternInsights_card_needs > div {
        margin-bottom: 3px; // from 5px
    }
    .patternInsights_card_behaviorTraits,
    .patternInsights_card_needs {
        padding: 7px 10px; // from 10 10
    }

    pattern-insights-card {
        .closableCard .pi-card_header {
            background: none;
        }
        .pi-card {
            box-shadow: none;
        }
        .pi-card .pi-card_header .pi-card_header_tools {
            display: none;
        }
        .personDashboard_content card {
            margin-bottom: 0px;
        }
        .pi-card .pi-card_content {
            padding: 0;
        }
        .patternInsights_cardContainer {
            margin: 0;
        }
        .pi-card .pi-card_header .pi-card_header_title {
            padding-left: 0;
        }
    }

    .svgLetter {
        height: 25px;
        width: 25px;
    }
    .behavioralCard {
        .pi-card .pi-card_header .pi-card_header_title {
            font-size: 17px; // down from 19
            padding: 7px 10px; // down from 10 10
        }
    }
}

.personDetailsHeader__badgeContainer {
    background-color: white;
    position: absolute;
    top: 95px;
    .flex-display();
    .justify-content(center);
    .align-items(center);
    border-radius: 50%;
    height: 120px;
    width: 120px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.personDetailsSideNav__Container {
    min-width: 264px;
    max-width: 284px;
    height: fit-content;
    margin: 0px 32px 0 0;
    .expansionPanel_content {
        padding-top: 0;
    }
}

.personDetailsSideNav__Title {
    width: 298px;
    margin: 44px 0px 10px 0px;
    overflow-wrap: normal;
}

.personDetailsSideNav__Section {
    padding: 8px 27px;
    box-sizing: border-box;
}

.personDetailsSideNav__SectionTop {
    padding: 8px 0px;
}

.personDetailsSideNav__SectionTitle {
    color: @color-neutral-900;
    font-size: 16px;
    font-weight: 500;
}
.personDetailsSideNav__Button {
    color: @color-person;
    background-color: #ffffff;
    font-size: 14px;
    padding: 4px 0;
    border: none;
    margin-bottom: 7px;
}

.outletContainer {
    padding: 45px;
    width: 100%;
    height: fit-content;
}

.exploratoryNavTopSection {
    justify-content: flex-end;
    border-bottom: 7px solid #f2f2f2;
}

.exploratoryNavBlock {
    height: 208px;
    width: 255px;
    margin: 21px;
    &:hover {
        color: white;
        background-color: @color-primary-900;
    }
}

.personDetailsSelector--empty {
    padding: 55px 190px 55px;
    margin: 25px;
    justify-content: center;
}

.personDetailsSelector {
    padding: 20px 0px 12px;
    justify-content: center;
    .bg-color-gradient-blue;
}

.personDetailsSelector__groupReportDropdown {
    padding-left: 10px;
    min-width: 200px;
    select {
        border: 1px solid @color-primary;
        color: @color-neutral-900;
        font-size: 13px;
    }
}

.selfIdentifiedText {
    font-size: 16px;
    color: @color-neutral-700;
    margin: 5px;
}

.personDetailsCTASection {
    width: 287px;
    margin: 10px 25px 10px 0px;
}

.concise-ref-prof-card-background {
    width: 115px;
    text-align: center;
    padding-top: 15px;
    border-radius: 5px;
}

.personEditLink {
    .clickable;
    .color-neutral-700;
    svg {
        stroke: @color-neutral-700;
    }
    font-size: 16px;
    font-weight: 300;
    height: 16px;
    &:hover {
        color: @color-primary;
        svg {
            stroke: @color-primary;
        }
    }
}
