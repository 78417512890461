@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors';

onboarding-welcome-component {
    .onboarding-welcome-container {
        display: flex;
        flex-direction: column;
        height: 759px;
        align-items: center;
        justify-content: space-evenly;
    }
}
