@import '../../../../../node_modules/@predictiveindex/client-core/styles/LivingStyleGuide/colors.less';
@import '../../../../../node_modules/@predictiveindex/client-core/styles/utilities.less';

.engageSelfServiceDashboard {
    .pi-dashboard {
        max-width: 850px;
    }
    .flex-display();
    .engageSelfServiceDashboard_title_icon {
        height: 45px;
        color: @electric-violet;
        margin: 21px 0px 0px 45px;
    }
    .engageSelfServiceDashboard_tips {
        background-color: @faded-purple;
        padding: 2px 16px;
        border-radius: 4px;
        .flex-display();
        .flex-direction(column);
        .engageSelfServiceDashboard_tips_header {
            font-size: 18px;
            color: @kingfisher;
            font-weight: 500;
            padding: 14px 0px;
        }
        .engageSelfServiceDashboard_tips_buttonSection {
            .flex-display();
            .engageSelfServiceDashboard_tips_buttonSection_button {
                margin-top: 20px;
                margin-bottom: 14px;
                &:first-child {
                    margin-right: 36px;
                }
            }
        }
    }
    .engageSelfServiceDashboard_reportInfo {
        .flex-display();
        .flex-direction(column);
        padding: 15px 0px;
        .engageSelfServiceDashboard_reportInfo_header {
            font-size: 22px;
            font-weight: bold;
        }
    }
    .engageSelfServiceDashboard_reportSection {
        margin-top: 15px;
        .flex-display();
        .flex-direction(column);
    }

    .supportInfoBoxParent {
        .cardTitle {
            margin-top: 35px;
        }
        .supportInfoBox {
            margin-left: 0;
        }
    }
}
